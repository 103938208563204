<template>
  <ion-page>
    <ion-content>
      <!--<div style="text-align:center; margin: 15px;" data-header-ad-wrapper>
        <VueScriptComponent v-if="adLinkTop && adLinkTop !=''" :script="adLinkTop" />
      </div>-->
      <div class="cf wrap noTop">
                <div class="controls cf">
                  <div class="refresh">
                    Aktualisierung alle <select name="refresh" class="refresh" v-model="selectedRefresh">
                      <option v-for="refresh in refreshSelect" :key="refresh.id" :value="refresh.id">{{ refresh.name }}</option>
                      </select>
                      <a @click="refreshScore()" class="refresh"><img src="~@/assets/images/refresh.gif"></a>
                  </div>
                </div>
              </div>
              
        <table style="margin-left: 0px;margin-top: 0px;margin-bottom: 0px;">
        <tr>
          <td colspan="6">
            <marquee-text  v-if="currentTicker && currentTicker.tickerText != ''" style="margin-left: 20px;" class="ticker-text white caption" :key="currentTicker.id" :duration="12" :repeat="15" :reverse="false">{{ currentTicker.tickerText }}&nbsp; &nbsp; &nbsp; 
          </marquee-text>
          </td>
        </tr>
        </table>

      <table style="color: black; margin-left: 10px;margin-top: 0px;margin-bottom: 10px;">
        <tr>
          <td>
            <select interface="action-sheet" v-model="selectedFlight">
              <option value="0">Flight A</option>
              <option value="1">Flight B</option>
            </select>
          </td>
          <td>
            <select interface="action-sheet" v-model="selectedRound">
              <option value="0">Runde 1</option>
              <option value="1">Runde 2</option>
              <option value="2">Runde 3</option>
            </select>
          </td>
          <td style="vertical-align: top;width: 60px;">
              <div><img style="margin-left: 10px;" width="60" :src="logoImg"></div>
          </td>
          <td style="vertical-align: center;width: auto; ">
              <h3 v-if="leagueName" style="margin-left:15px;">{{ leagueName }}</h3>
          </td>
        </tr>
      </table>
        <table style="color: black;" class="ranking matchplay" v-for="(result, i) in bracketsFound" :key="i" :value="result">
          <colgroup>
          <col width="40%">
          <col width="60">
          <col width="100">
          <col width="60">
          <col width="40%">
          </colgroup>
          <tbody>
          <tr>
          <td colspan="5">
          <div class="roundInfo"> {{ result.header }}</div>
          </td>
          </tr>
          <tr>

          <th style="text-align:right">Team</th>
          <th style="text-align: center">Punkte</th>
          <th style="text-align: center">Ergebnis</th>
          <th style="text-align: center">Punkte</th>
          <th style="text-align:left">Team</th>
          </tr>

          <tr v-for="(match, j) in result.match" :key="j" :value="match" >
            <td style="text-align:right">
               {{ match.team1 }}
              </td>
              <td style="text-align: center;">{{ match.team1Points }}</td>
              <td style="text-align: center;"></td>
              <td style="text-align: center;">{{ match.team2Points }}</td>
              <td>{{ match.team2 }}</td>
          </tr>
          <template  v-for="(match, j) in result.matches" :key="j" :value="match">
            <tr class="ranking matchplay" >
              <td style="text-align:right">
                <a v-if="match.result" class="openScore" @click="match.showScorecard == true ? match.showScorecard = false : match.showScorecard = true">{{ match.players1 }}</a>
                {{ match.result == undefined ? match.players1 : '' }}
              </td>
              <td style="text-align: center;">
                <a v-if="match.result" class="openScore" @click="match.showScorecard == true ? match.showScorecard = false : match.showScorecard = true">{{ match.points1 }}</a>
                {{ match.result == undefined ? match.points1 : '' }}
                {{ match.result == undefined ? match.result : '' }}
              </td>
              <td style="text-align: center;">
                <a v-if="match.result" class="openScore" @click="match.showScorecard == true ? match.showScorecard = false : match.showScorecard = true">{{ match.result }}</a>
                <br> <span @click="match.showScorecard == true ? match.showScorecard = false : match.showScorecard = true" v-if="match.thru && match.thru != ''">{{ match.thru }}</span>
              </td>
              <td style="text-align: center;">
                <a v-if="match.result" class="openScore" @click="match.showScorecard == true ? match.showScorecard = false : match.showScorecard = true">{{ match.points2 }}</a>
                {{ match.result == undefined ? match.points2 : '' }}
                {{ match.result == undefined ? match.result : '' }}
              </td>
              <td>
                <a v-if="match.result" class="openScore" @click="match.showScorecard == true ? match.showScorecard = false : match.showScorecard = true">{{ match.players2 }}</a>
                {{ match.result == undefined ? match.players2 : '' }}
              </td>
            </tr>
            <tr class="playerScore" v-if="match.result != undefined && match.showScorecard == true">
              <td colspan="5">
              <table class="playerScore matchplay">
                <tbody>
                <tr>
                  <td>Loch</td>
                  <td v-for="(hole, h) in match.numHoles" :key="h" :value="hole">{{ h+1 }}</td>
                </tr>
                <tr>
                  <td>{{match.players1}}</td>
                  <td v-for="(hole, h) in match.numHoles" :key="h" :value="hole">{{ (match.player1Scores[h] -  match.player2Scores[h]) > 0 ? (match.player1Scores[h] - match.player2Scores[h]) + ' auf' : '' }}</td>
                </tr>
                <tr class="as">
                  <td></td>
                  <td v-for="(hole, h) in match.numHoles" :key="h" :value="hole">{{ (match.player1Scores[h] != null && match.player1Scores[h] != -2 && match.player1Scores[h] == match.player2Scores[h]) ? 'AS' : '' }}</td>
                </tr>
                <tr>
                  <td>{{match.players2}}</td>
                  <td v-for="(hole, h) in match.numHoles" :key="h" :value="hole">{{ (match.player2Scores[h] - match.player1Scores[h]) > 0 ? (match.player2Scores[h]  - match.player1Scores[h]) + ' auf' : '' }}</td>
                </tr>
                </tbody>
              </table>
              </td>
            </tr>
          </template>
          </tbody>
        </table>

        <div style="max-width: 1024px; width: auto; margin:0 auto;">
                <div><img width="1024" :src="footerImg"></div>
              </div>                
              <div style="font-size:small; color: black; margin:0 auto;">
                <div style="margin: 10px;"><a href="https://www.clubinone.de" target="_blank">Powered by CLUB IN ONE</a></div>
              </div>                

    </ion-content>
  </ion-page>
</template>

<script>
import { IonTitle, IonButton, IonToolbar, IonHeader, loadingController,
IonMenuButton, IonButtons, IonGrid, IonImg, IonCol, IonRow, IonItemDivider, IonSearchbar, IonCheckbox,
IonApp, IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, 
IonNote, IonRouterOutlet, IonSplitPane, IonSelect, IonSelectOption, IonAvatar, IonFabList, 
IonFabButton, IonFab, IonCardContent, IonCardHeader, IonCardTitle, IonCard, IonPage, isPlatform } from '@ionic/vue';
import { defineComponent, ref, computed, watch } from 'vue';
import { useQuery } from '@vue/apollo-composable'
import findBracketRoundQueryFile from '../graphql/findBracketRound.query.gql'
import { useRoute, useRouter } from 'vue-router';
import { chevronBackOutline, chevronForwardOutline, arrowDownSharp, arrowUpSharp } from 'ionicons/icons';
import * as dayjs from 'dayjs';
import VueScriptComponent from 'vue-script-component'
import MarqueeText from 'vue-marquee-text-component'

export default defineComponent({
  name: 'Home',
  components: {
    IonApp, IonImg, IonAvatar, IonPage,
    IonContent, 
    IonIcon, 
    IonItem, 
    IonLabel,
    IonList, 
    IonListHeader, 
    IonMenu, 
    IonMenuToggle, 
    IonNote, 
    IonRouterOutlet, 
    IonSplitPane,
    IonSearchbar,
    IonItemDivider,
    IonGrid, 
    IonCol, 
    IonRow,
    IonTitle, IonButton, IonToolbar, IonHeader, IonMenuButton, IonButtons, IonSelect, IonSelectOption,
    IonFabList, IonFabButton, IonFab, IonCardContent, IonCardHeader, IonCardTitle, IonCard, IonCheckbox, VueScriptComponent, MarqueeText
  },
  data() {
    return {
      weekDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
    }
  },
  setup() {
    const route = useRoute()
    const selectedFlight = ref(route.params.groupid)
    const selectedRound = ref(route.params.roundid)
    let selectedRefresh = ref(1)
    let isLoaded = ref(false)
    let loadingSpinner = null
    const router = useRouter()

    const {result: findBracketRoundResult, refetch: findBracketRoundRefresh, loading: findBracketRoundLoading } = useQuery(findBracketRoundQueryFile, () => ({ group: parseInt(selectedFlight.value), round_id: parseInt(selectedRound.value), league_id: parseInt(route.params.lid) }), () => ({ fetchPolicy: 'no-cache', pollInterval: selectedRefresh.value == 0 ? 0 : 60000*5 }) )

    const refreshSelect = [{ id: 0, name: 'Nein' }, { id: 1, name: '5 Minuten', selected: true }]
    const bracketsFound = computed(() => findBracketRoundResult.value?.findBracketRound?.bracket ?? [])
    const footerImg = computed(() => findBracketRoundResult.value?.findBracketRound?.footerImg ?? '')
    const logoImg = computed(() => findBracketRoundResult.value?.findBracketRound?.logoImg ?? '')
    const adLinkTop = computed(() => findBracketRoundResult.value?.findBracketRound?.adLinkTop ?? '')
    const leagueName = computed(() => findBracketRoundResult.value?.findBracketRound?.leagueName ?? '')
    const currentTicker = computed(() => findBracketRoundResult.value?.findBracketRound?.currentTicker ?? '')
    const selRound = computed(() => findBracketRoundResult.value?.findBracketRound?.selectedRound ?? 0)

    watch(selectedFlight, (newVal, oldVal) => {
      isLoaded.value = false
    })
    watch(selectedRound, (newVal, oldVal) => {
      isLoaded.value = false
    })
    watch(selRound, (newVal, oldVal) => {
      if (newVal != selectedRound.value)
        selectedRound.value = newVal
        //router.push('/dmm/' + parseInt(route.params.lid) + '/' + parseInt(route.params.groupid) + '/' + newVal)
    })

    /*loadingController.create().then(res => {
      loadingSpinner = res
      loadingSpinner.present().then(async () => { 
        console.log(findBracketRoundLoading.value, loadingSpinner)
        if (!findBracketRoundLoading.value && loadingSpinner) {
          console.log('dismiss')
          loadingSpinner.dismiss() 
          loadingSpinner = null
        }
      })
    }).catch(err => { })*/

    watch(findBracketRoundLoading, (newLoading, pervLoading)=> {
      if (!newLoading && loadingSpinner != null) {
          isLoaded.value = true
          loadingSpinner.dismiss()
          loadingSpinner = null
          return
      }
      else if (!isLoaded.value && newLoading) {
        isLoaded.value = true
        loadingController.create().then(res => {
              loadingSpinner = res
              loadingSpinner.present().then(async () => { 
                if (!findBracketRoundLoading.value && loadingSpinner) {
                  loadingSpinner.dismiss() 
                  loadingSpinner = null
                }
              })
            }).catch(err => { })
      }
    })

    return {
      bracketsFound, chevronBackOutline, chevronForwardOutline, dayjs, arrowDownSharp, arrowUpSharp, isPlatform, selectedFlight, selectedRound, footerImg, logoImg, adLinkTop, refreshSelect, selectedRefresh, findBracketRoundRefresh, leagueName, currentTicker
    }
  },
  methods: {
    refreshScore() {
      this.isLoaded = false
      this.findBracketRoundRefresh()
    },
    showScorecard() {
      console.log('here')
    },
  }
});

if (document.location.search.match(/type=embed/gi)) {
  window.parent.postMessage("resize", "*");
}

</script>

<style scoped>
.cf:after {
    content: "";
    display: table;
    clear: both;
}

div.wrap,
div.error {
    padding: 7px;
}

div.wrap.noTop {
    padding-top: 0;
}

div.wrap ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

div.wrap > ul > li {
    padding-right: 4px;
    padding-bottom: 15px;
    width: 25%;
    float: left;
}

div.wrap ul li h2 {
    font: bold 1.5em/1.5 "Roboto", sans-serif;
    padding-bottom: 3px;
    border-bottom: 1px solid;
    margin: 0;
}

div.wrap > ul > li > ul {
    padding-right: 30px;
    padding-top: 7px;
}

div.wrap > ul > li > ul > li {
    padding: 10px 5px 10px 1em;
    font-size: 1.2em;
    font-weight: 600;
    cursor: pointer;
}

div.wrap > ul > li > ul > li > ul {
    padding-left: 20px;
}

div.wrap > ul > li > ul > li > ul {
    margin-top: 5px;
    padding-top: 5px;
    border-top: solid 2px;
    display: none;
}

div.wrap > ul > li > ul > li > ul > li {
    padding: 5px 0;
}

div.wrap ul a {
    text-decoration: none;
    font-weight: normal;
}

div.wrap ul a:hover {
    text-decoration: underline;
}

div.refresh {
    float: right;
    line-height: 1;
    padding-top: 7px;
}

div.refresh select {
    font-size: 12px;
    margin: 0;
    width: 100px;
}

div.refresh a {
    display: inline-block;
    cursor: pointer;
}

div.filter {
    float: left;
    max-width: 400px;
    padding-left: 7px;
}

div.filter a {
    text-decoration: none;
    background: transparent url('~@/assets/images/arrow.gif') no-repeat right center;
    padding-right: 20px;
}

div.filter select {
    display: block;
    width: 100%;
}

ion-content {
    margin: 0;
    padding: 0;
    font: normal 12px/1.3 Arial, Verdana, sans-serif !important;
    --ion-background-color: white;
}

select {
    margin-top: 7px;
    font-size: 1.2em;
    font-weight: 600;
    height: 30px;
    max-width: 100%;
    padding: 4px 6px;
    border: 1px solid;
}

span.square {
    display: inline-block;
    width: 18px;
    height: 18px;
    border: solid 1px;
    margin-left: 20px;
    margin-bottom: -4px;
}

p.legend {
    font-size: 12px;
    text-align: right;
    padding-right: 20px;
    margin-bottom: 15px;
}

p.right {
    text-align: right;
}

img {
    max-width: 100%;
    height: auto;
}

div.controls {
    margin: 0 0 20px 0;
}

div.head {
    font-size: 19px;
    font-weight: bold;
    border-bottom: 1px solid;
}

div.head span {
    display: inline-block;
}

div.head span.rank {
    width: 50px;
    padding-left: 5px;
}

div.head span.score {
    width: 70px;
    float: right;
    text-align: center;
}

.ticker-text {
    height: 150%;
    font-size: 25px;
    color: red;
}

div.roundInfo {
    padding: 16px 0 5px 0;
    font-size: 18px;
}

table.ranking span,
table.ranking a,
table.singleRanking span {
    text-decoration: none;
    cursor: pointer;
}

table.ranking tr.sum > td {
    border-top: solid 2px;
}

table.ranking,
table.singleRanking {
    width: 100%;
    table-layout: auto;
    margin-bottom: 30px;
}

table.ranking tr,
table.singleRanking tr {
    height: 30px;
}

table.ranking > thead > tr > th,
table.singleRanking > thead > tr > th {
    padding: 3px 5px;
    font-weight: bold;
    font-size: 13px;
    text-align: center;
    border: solid 1px;
    border-top: none;
    border-bottom-width: 3px;
    width: 75px;
}

table.ranking > thead > tr > th:nth-child(1),
table.ranking > thead > tr > th:nth-child(2),
table.singleRanking > thead > tr > th:nth-child(2),
table.singleRanking > thead > tr > th:nth-child(3),
table.singleRanking > tbody > tr > td:nth-child(2),
table.singleRanking > tbody > tr > td:nth-child(3) {
    width: auto;
    text-align: left !important;
}

table.ranking > thead > tr > th:first-child,
table.ranking > tbody > tr > td:first-child,
table.singleRanking > thead > tr > th:first-child,
table.singleRanking > tbody > tr > td:first-child {
    border-left: none;
}

table.ranking > thead > tr > th:last-child,
table.ranking > tbody > tr > td:last-child,
table.singleRanking > thead > tr > th:last-child,
table.singleRanking > tbody > tr > td:last-child {
    border-right: none;
}

table.ranking > tbody > tr > td,
table.singleRanking > tbody > tr > td {
    font-size: 14px;
    padding: 3px 5px;
    border: solid 1px;
    text-align: center;
}

table.ranking.matchplay > tbody > tr > td {
    text-align: left;
}

table.ranking > tbody > tr > td:nth-child(1),
table.ranking > tbody > tr > td:nth-child(2) {
    text-align: left;
}

table.ranking tr.sum > td {
    border-top: solid 2px;
}

table.ranking > tbody > tr.teamEnd > td,
table.singleRanking > tbody > tr.teamEnd > td {
    border-bottom: solid 3px;
}

table.ranking tr.playerScore,
table.singleRanking tr.playerScore {
    display: table-row;
}

table.ranking tr.playerScore > td,
table.singleRanking tr.playerScore > td {
    padding: 5px 3px;
}

table.ranking a,
table.singleRanking a {
    text-decoration: none;
}

table.ranking a:hover,
table.singleRanking a:hover {
    text-decoration: underline;
}

table.ranking > tbody > tr > td {
    font-size: 14px;
    padding: 3px 5px;
    border: solid 1px;
    text-align: center;
}

table.playerScore {
    width: 100%;
}

table.playerScore thead tr th {
    font-weight: normal;
    border: solid 1px;
    border-top: none;
    border-bottom-width: 3px;
    text-align: center;
    padding: 3px 5px;
}

table.playerScore > tbody > tr > td {
    border: solid 1px;
    text-align: center;
    padding: 3px 5px;
}

table.playerScore > thead > tr > th:first-child,
table.playerScore > tbody > tr > td:first-child {
    border-left: none;
}

table.playerScore > thead > tr > th:last-child,
table.playerScore > tbody > tr > td:last-child {
    border-right: none;
}

table.playerScore td.small,
table.playerScore th.small {
    width: 32px;
}

table.playerScore tr :nth-child(2) {
    width: 40px;
}

table.playerScore tr :nth-child(21) {
    width: 58px;
}

table.playerScore tbody tr td {
}

table.playerScore tbody tr td:first-child {
    font-weight: normal !important;
    text-align: right;
}

table.playerScore tbody tr:first-child td {
    font-weight: bold;
}

table.playerScore.matchplay tr td {
    width: 50px;
}

table.playerScore.matchplay tr td:first-child {
    width: auto;
}

table.playerScore.matchplay tr.as td {
}

.eagle {
    background: #ff9050 !important;
}

.birdie {
    background: #ff7070 !important;
}

.par {
    background: lightgray !important;
}

.bogey {
    background: #80bb80 !important;
}

.doublebogey {
    background: #a0a0cc !important;
}

.triplebogey {
    background: #60608c !important;
}

span.square {
    display: inline-block;
    width: 18px;
    height: 18px;
    border: solid 1px;
    margin-left: 20px;
    margin-bottom: -4px;
}

p.legend {
    font-size: 12px;
    text-align: right;
    padding-right: 20px;
    margin-bottom: 15px;
}

p.right {
    text-align: right;
}

img {
    max-width: 100%;
    height: auto;
}

</style>