module.exports = {
  translation: {
    "total": "Total",
    "round": "Round",
    "country": "Country",
    "noScoresFound": "No scores found for this tournament.",
    "position": "Position",
    "thru": "Thru",
    "eagle": "Eagle or better",
    "birdie": "Birdie",
    "par": "Par",
    "bogey": "Bogey",
    "doublebogey": "Double Bogey",
    "triplebogey": "Triple Bogey or worse",

    // Errors
 }
}