<template>
  <ion-app>
  <ion-split-pane content-id="main">
    <!-- the main content -->
     <div class="ion-page" id="main">
      <ion-router-outlet></ion-router-outlet>
    </div>
  </ion-split-pane>
  </ion-app>
</template>

<script>
import { toastController, modalController, IonTitle, IonButton, IonToolbar, IonHeader, IonMenuButton, 
IonButtons, IonGrid, IonImg, IonCol, IonRow, IonItemDivider, IonSearchbar, IonApp, IonContent, IonIcon, IonItem, 
IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonNote, IonRouterOutlet, IonSplitPane, IonSelect, 
IonSelectOption, IonFabList, IonFabButton, IonFab, IonBadge, alertController, IonProgressBar, IonCardSubtitle, IonCardTitle, } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {  } from 'ionicons/icons';
import { useQuery, useResult, useMutation} from '@vue/apollo-composable'
import { useFavicon } from '@vueuse/core'
import imageDGV from "./assets/dgv.png"
import imageDGL from "./assets/dgl.png"

export default defineComponent({
  name: 'App',
  components: {
    IonApp, IonImg,
    IonContent, 
    IonIcon, 
    IonItem, 
    IonLabel, 
    IonList, 
    IonListHeader, 
    IonMenu, 
    IonMenuToggle, 
    IonNote, 
    IonRouterOutlet, 
    IonSplitPane,
    IonSearchbar,
    IonItemDivider,
    IonGrid, 
    IonCol, 
    IonRow,
    IonTitle, IonButton, IonToolbar, IonHeader, IonMenuButton, IonButtons, IonSelect, IonSelectOption,
    IonFabList, IonFabButton, IonFab, IonBadge, IonProgressBar, IonCardSubtitle, IonCardTitle,
  },
  setup() {
    if (window.location.href.includes('dgvpublic') || window.location.href.includes('localhost'))
     useFavicon(imageDGV)
    else
      useFavicon(imageDGL)

    return { 
    }
  },
  methods: {
  },
  data() {
    return {
    }
  }
});
</script>

<style lang="scss">


ion-card {
border-radius: 20px;
}

#app {
  font-family: "Roboto", Helvetica, Arial, sans-serif !important;
  --ion-font-family: "Roboto", Helvetica, Arial, sans-serif !important;
  background-color: #004587;
  font: normal 16px/1.3 "Roboto", Helvetica, Arial, sans-serif !important;
}

ion-content {
    --ion-background-color:#004587;
}

.cursor {
    cursor: pointer !important;
}

.tournamentLists-modal .modal-wrapper {
    width: 60%;
    height: 410px;
 }

img,video {
    max-width: 100%;
    height: auto;
}

.show-modal .modal-wrapper {
    width: 95%;
    height: 95%;
 }

.showscorecard-modal .modal-wrapper {
    width: 85%;
    height: 500px;
 }

.register-modal .modal-wrapper {
    height: 75%;
 }

.booking-modal .modal-wrapper {
    height: 40%;
 }

</style>