import { createApp, provide, h } from 'vue'
import App from './App.vue'
import router from './router';
import { ApolloClient, InMemoryCache, gql, from } from '@apollo/client';
import { RetryLink } from 'apollo-link-retry'
import { DefaultApolloClient } from '@vue/apollo-composable';
import translateGlobalProperties from './plugins/translateGlobalProperties'
import i18next from 'i18next';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faFlag);

const en = require('./i18nData/en');
const de = require('./i18nData/de');

i18next.init({
  lng: 'de',
  debug: false,
  resources: {
    en,
    de,
  }
})

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
//import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
//import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
/*import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
*/

/* Theme variables */
import './theme/variables.css';

import { createStore } from 'vuex'

import { ApolloLink, concat } from 'apollo-link';
import { HttpLink } from 'apollo-link-http'

const httpLink = new HttpLink({
  uri: process.env.VUE_APP_GRAPHQL_URI
})

const defaultOptions = { 
  watchQuery: {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  },
  query: {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  },
  mutate: {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  }
}

const retry = new RetryLink(
  { 
    attempts : { max : Infinity },
    delay: { initial: 10000, max: 10000, jitter: false }, 
  }) 

const links = ApolloLink.from([
  concat(retry, httpLink),
]);

const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false
  }),
  link: links,
  defaultOptions
})

export const store = createStore({
  state () {
    return {
    }
  },
  actions: {
  },
  mutations: {
  },
  getters: {
  }
})

const app = createApp(
  {
    setup () {
      provide(DefaultApolloClient, apolloClient)
    },
    render() {
      return h(App)
    }
  }
)
.component("font-awesome-icon", FontAwesomeIcon)
.use(IonicVue)
.use(translateGlobalProperties)
.use(router);
  
router.isReady().then(() => {
  app.mount('#app');
});


