<template>
    <div class="ion-margin">
          <ion-grid class="ion-no-margin ion-no-padding">
            <ion-row>
              <ion-col size="11">
                <h6 class="uppercase"><b> {{ this.leagueInfo }}</b></h6>
              </ion-col>
              <ion-col size="1">
                <ion-icon style="font-size: 25px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" class="ion-float-end cursor"></ion-icon>
              </ion-col>
            </ion-row>
          </ion-grid>
          
          <ion-label color="danger" style="font-weight: bold;font-size: 16px;text-transform: uppercase;">Blitztabelle</ion-label>
          <table class="tabelle-dgl">
            <tbody>
              <tr style="border-bottom: none!important;">
                <th>Rang | Team</th>
                <th>Punkte</th>
                <th>Total</th>
                <th>+/-</th>
              </tr>
              <template v-for="(team, index) in teamScores" :key="index">
                <tr>
                  <td>
                    <ion-grid class="ion-no-margin ion-no-padding">
												<ion-row>
													<ion-col size="1" class="fixedcol">
														<span v-if="team.rankAllPoints%2!=0" class="float-left badge dgl">{{ team.rankAllPoints }}</span>
														<span v-if="team.rankAllPoints%2==0" class="float-left badge dgl-table">{{ team.rankAllPoints }}</span>
													</ion-col>
													<ion-col size="1" class="fixedcol"><div class="clublogo"><img src="" alt></div></ion-col>
													<ion-col size="2" class="fixedcol">
                            <b><ion-icon v-if="team.currentPoints < team.allPoints && team.rankAllPoints < team.teamRankPrev" :icon="arrowUpOutline" color="success" style="--ionicon-stroke-width: 64px;font-weight: bold; margin-right: 15px; zoom: 1.4"></ion-icon></b>
                            <b><ion-icon v-if="team.currentPoints < team.allPoints && team.rankAllPoints > team.teamRankPrev" :icon="arrowDownOutline" color="danger" style="--ionicon-stroke-width: 64px;font-weight: bold;font-size: 16px; margin-right: 10px;zoom: 1.4"></ion-icon></b>
                            <b><ion-icon v-if="team.currentPoints < team.allPoints && team.rankAllPoints == team.teamRankPrev" :icon="removeOutline" color="primary" style="--ionicon-stroke-width: 64px;font-weight: bold;font-size: 16px; margin-right: 10px;zoom: 1.4"></ion-icon></b>
                          </ion-col>
													<ion-col size="8"><b>{{ team.name }}</b></ion-col>
												</ion-row>
											</ion-grid>

                  </td>
                  <td style="text-align: center; vertical-align:top">
                    <b>{{ team.currentPoints }}</b>
                  </td>
                  <td style="text-align: center; vertical-align:top">
                    <b>{{ team.allPoints }}</b>
                  </td>
                  <td style="text-align: center; vertical-align:top">
                    <b>{{ team.sumOverPar }}</b>
                  </td>
                  
                </tr>
              </template>
            </tbody>
          </table>
    </div>


  <!-- <ion-header>
    <ion-toolbar>
      <ion-item lines="none">
        <ion-title><h3 class="headline_detail">Blitztabelle</h3></ion-title>
        <ion-icon slot="end" style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" name="closeOutline"></ion-icon>
      </ion-item>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding" style="--ion-background-color: #004587;">
    <ion-card>
    <ion-grid>
      <ion-row class="content" style="padding-top: 15px;"><ion-col><h1 class="headline_detail">{{ this.leagueInfo }}</h1></ion-col></ion-row>
      <ion-row class="content">
        <ion-col>
          <ion-card>
            <ion-grid>
                <ion-row style="margin-left: 10px;">
                  <ion-col size="1">
                    <ion-label class="ion-text-wrap gray">Rang</ion-label>
                  </ion-col>
                  <ion-col size="1">
                    <ion-label class="ion-text-wrap">&nbsp;</ion-label>
                  </ion-col>
                  <ion-col>
                    <ion-label class="ion-text-wrap gray">Team</ion-label>
                  </ion-col>
                  <ion-col size=1>
                    <ion-label class="ion-text-wrap gray">Punkte</ion-label>
                  </ion-col>
                </ion-row>

                <template v-for="(item, index) in teamScores" :key="index">
                  <ion-card :style="index % 2 == 0 ? 'background-color: #dddcdd' : 'background-color: #004587'">
                  <ion-row>
                    <ion-col size="1">
                      <svg width="22" height="30" style="margin-top: 10px;">
                        <circle v-if="index % 2 == 0" cx="10" cy="10" r="10" fill="#004587" />
                        <circle v-if="index % 2 != 0" cx="10" cy="10" r="10" fill="white" />
                        <text v-if="index % 2 == 0" x="45%" y="35%" text-anchor="middle" fill="white" font-size="12px" font-family="'Roboto',sans-serif" dy=".3em">{{ item.rankAllPoints }}</text>
                        <text v-if="index % 2 != 0" x="45%" y="35%" text-anchor="middle" fill="#004587" font-size="12px" font-family="'Roboto',sans-serif" dy=".3em">{{ item.rankAllPoints }}</text>
                      </svg>         
                      <ion-icon v-if="index != 2 && index != 3" :icon="index <= 1 ? chevronUp : chevronDown" :color="index <= 1 ? 'success' : index > 3 ? 'danger' : 'primary'" style="font-size: 16px; margin-left: 10px; margin-bottom: 15px;"></ion-icon>
                    </ion-col>
                    <ion-col size="1">
                      <ion-icon v-if="index == 2 || index == 3" style="font-size: 16px; margin-right: 10px"></ion-icon>
                      <ion-icon v-if="!allFinished && item.rankAllPoints < item.teamRankPrev" :icon="arrowUpOutline" color="success" style="font-size: 16px; margin-right: 10px"></ion-icon>
                      <ion-icon v-if="!allFinished && item.rankAllPoints > item.teamRankPrev" :icon="arrowDownOutline" color="danger" style="font-size: 16px; margin-right: 10px"></ion-icon>
                      <ion-icon v-if="!allFinished && item.rankAllPoints == item.teamRankPrev" :icon="removeOutline" color="primary" style="font-size: 16px; margin-right: 10px"></ion-icon>
                      
                    </ion-col>
                    <ion-col style="margin-top: 12px;">
                      <ion-label v-if="!allFinished" class="ion-text-wrap teams" :style="index % 2 == 0 ? 'color: #2e2e2e' : 'color: white'">{{ item.name }}</ion-label>
                      <ion-label v-if="allFinished" class="ion-text-wrap teams" :style="index % 2 == 0 ? 'color: #2e2e2e' : 'color: white'">{{ item.name }}</ion-label>
                    </ion-col>
                    <ion-col style="margin-top: 12px;" size=1>
                      <ion-label class="ion-text-wrap teams" :style="index % 2 == 0 ? 'color: #2e2e2e;margin-left: 25px;' : 'color: white;margin-left: 25px;'">{{ item.allPoints }}</ion-label>
                    </ion-col>

                  </ion-row>
                </ion-card>

              </template>
              </ion-grid>

          </ion-card>
      </ion-col>
      </ion-row>

      <ion-row class="content" style="padding-left: 10px;">
        <ion-grid>
          <ion-row>
            <ion-col size="auto"><ion-icon :icon="chevronUp" color="success" style="font-size: 16px; margin-right: 10px;padding-top: 5px;"></ion-icon></ion-col>
            <ion-col size="auto"><ion-label class="ion-text-wrap gray">Aufstiegs- bzw. Qualifikationsplätze</ion-label></ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="auto"><ion-icon :icon="chevronDown" color="danger" style="font-size: 16px; margin-right: 10px;padding-top: 5px;"></ion-icon></ion-col>
            <ion-col size="auto"><ion-label class="ion-text-wrap gray">Abstiegs- bzw. Relegationsplätze</ion-label></ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="auto"><ion-icon :icon="arrowUpOutline" color="success" style="font-size: 16px; margin-right: 10px;padding-top: 5px;"></ion-icon></ion-col>
            <ion-col size="auto"><ion-label class="ion-text-wrap gray">Verbesserte Platzierung im Vergleich zum vorherigen Spieltag</ion-label></ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="auto"><ion-icon :icon="arrowDownOutline" color="danger" style="font-size: 16px; margin-right: 10px;padding-top: 5px;"></ion-icon></ion-col>
            <ion-col size="auto"><ion-label class="ion-text-wrap gray">Verschlechterte Platzierung im Vergleich zum vorherigen Spieltag</ion-label></ion-col>
          </ion-row>
        </ion-grid>
      </ion-row>

    </ion-grid>
  </ion-card>
  </ion-content>-->
</template>

<script>
import { IonButton, IonContent, IonHeader, IonTitle, toastController,
IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonItemDivider,
IonGrid, IonCol, IonRow, modalController, IonCheckbox, IonCard, IonCardHeader, IonCardTitle, isPlatform,
IonAvatar } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { closeOutline, arrowUpOutline, arrowDownOutline, removeOutline, square, chevronDown, chevronUp } from 'ionicons/icons';

export default defineComponent({
  name: 'ShowEntryListDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonItemDivider, IonCheckbox, IonCard, IonCardHeader,
                IonCardTitle, IonAvatar,
              },
  props: ['propsData'],
  setup() {
    let teamScores = ref(null)
    let allFinished = ref(false)
    let leagueInfo = ref('')
    return { closeOutline, teamScores, isPlatform, allFinished, arrowUpOutline, arrowDownOutline, removeOutline, square, leagueInfo, chevronDown, chevronUp
    }
  },
  mounted() {
    this.allFinished = this.propsData.allFinished
    /*if (this.allFinished)
      this.teamScores = this.propsData.teamScores.sort((a,b) => (a.allPoints > b.allPoints) ? 1 : ((b.allPoints > a.allPoints) ? -1 : 0)).reverse().sort((a,b) => (a.teamRankPrev > b.teamRankPrev) ? 1 : ((b.teamRankPrev > a.teamRankPrev) ? -1 : 0))
    else*/
      this.teamScores = this.propsData.teamScores.sort((a,b) => (a.sumOverPar - b.sumOverPar)).sort((a,b) => (a.rankAllPoints - b.rankAllPoints))

    this.leagueInfo = this.propsData.leagueInfo
  },
  data() {
    var listDetail = []
    var listHeader = []
    return {  
      listDetail, listHeader
    }
  },
  methods: {
    closeDialog() {
      return modalController.dismiss()
    },
  }
});
</script>

<style scoped>

.gray { color: #2e2e2e;}
.blue { color: #004587;}

h1,h2,h3,h4,h5,h6{
font-weight:bold;
color: #004587;
}

ion-card{
  background-color: white;
}

.my-grid {
	background-color: #004587;
}

.teams {
    color: #004587;
    text-decoration: none;
    outline: 0;
    font-weight: 600;
    font-size: 0.9em;
}

h1.headline_detail {
    margin: 10px 0 0px 20px;
    font-size: 1.6em;
    color: #004587;
    font-weight: bold;
    display: block;
}

.content {
  background-color: white;
}
.slider {
    min-height: 1.4em;
    width: 100%;
    float: left;
    background: #575d62;
    -webkit-border-top-left-radius: 10px;
    -webkit-border-top-right-radius: 10px;
    -khtml-border-top-left-radius: 10px;
    -khtml-border-top-right-radius: 10px;
    -moz-border-radius-topleft: 10px;
    -moz-border-radius-topright: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden
}

.playerImg {
  width: 40px; 
  height: 40px;
}

.playerImgBig {
  width: 120px; 
  height:120px
}

</style>