<template>
  <ion-page>
    <ion-content v-if="findgetIndexPageLoading == false" ref="content">
    <!-- <div>
        <ReviveAd :zoneId="1" reviveId="99147cafddec62abad2785215643bdc6"></ReviveAd>
      </div>-->

		<ion-grid class="my-grid ion-margin">
			<ion-row class="parent-row">
					<div style="text-align:center;" data-header-ad-wrapper>
						<VueScriptComponent v-if="adLinkTop && adLinkTop !=''" :script="adLinkTop" />
					</div>
			</ion-row>
		</ion-grid>


		<div class="grid-container">
			
			<div v-if="!hasParameter" class="grid-x grid-padding-x">

				<div class="large-4 medium-4 cell">
					<div class="primary callout">
						<p><b><span class="badge dgl">1</span> Livescoringkategorie auswählen</b></p>
						<select name="tableCategoryID" id="tableCategoryID" v-model="tableCategory">
							<option value="0">Spieltage</option>
							<option value="1">Final Four</option>
							<option value="2">Aufstiegsspiele</option>
						</select>
					</div>
				</div>
			
				<div class="large-4 medium-4 cell">
					<div class="primary callout">
						<p><b><span class="badge dgl">2</span> Suchkriterien eingeben</b></p>
						<ul class="tabs" data-tabs="5mw4py-tabs" id="tabs_search">
							<li class="tabs-title tabs-width-50 is-active"><a :aria-selected="filterSelected" @click="filterSelected=!filterSelected;searchText=''">Filter</a></li>
							<li class="tabs-title tabs-width-50"><a :aria-selected="!filterSelected" @click="filterSelected=!filterSelected">Suche</a></li>
						</ul>
						<div class="tabs-content" data-tabs-content="tabs_search">
							<div v-if="filterSelected && tableCategory == '0'" class="tabs-panel is-active" id="tab_01">
								<b>Nach Geschlecht, Liga oder Staffel filtern</b>
								<label>Geschlecht:
									<select name="gender" class="uk-select" v-model="searchGender">
										<option :value="league.header == 'Damen' ? 'F' : 'M'" v-for="(league, i) in structureFound.filter(f => f.header.includes('Damen') || f.header.includes('Herren'))" :key="i">{{ league.header }}</option>
									</select>
								</label>
								<label>Liga:
									<select name="ligaebene" class="uk-select" v-model="searchLiga">
										<option :value="{ id: i, name: league.name }" v-for="(league, i) in structureFound.find(f => f.header == (searchGender == 'F' ? 'Damen' : 'Herren'))?.subLeagues" :key="i">{{ league.name }}</option>
									</select>
								</label>
								<label>Staffel:
									<select name="region" class="uk-select" v-model="searchStaffel">
										<option :value="{ id: i, name: league.name }" v-for="(league, i) in structureFound.find(f => f.header == (searchGender == 'F' ? 'Damen' : 'Herren'))?.subLeagues?.find(f => f.name == searchLiga.name).subLeagues" :key="i">{{ league.name }}</option>
									</select>
								</label>
							</div>
							<div v-if="filterSelected && tableCategory == '1'" class="tabs-panel is-active" id="tab_01">
								<b>Nach Geschlecht filtern</b>
								<label>Geschlecht:
									<select name="gender" class="uk-select" v-model="searchGender">
										<option :value="league.name == 'Damen' ? 'F' : 'M'" v-for="(league, i) in structureFound.find(f => f.header.includes('FINAL FOUR'))?.subLeagues" :key="i">{{ league.name }}</option>
									</select>
								</label>
							</div>
							<div v-if="filterSelected && tableCategory == '2'" class="tabs-panel is-active" id="tab_01">
								<b>Nach Liga filtern</b>
								<label>Liga:
									<select name="gender" class="uk-select" v-model="searchAufstieg">
										<option :value="i" v-for="(league, i) in structureFound.find(f => f.header.includes('Aufstieg'))?.subLeagues" :key="i">{{ league.name }}</option>
									</select>
								</label>
							</div>
							<div class="tabs-panel is-active" id="tab_02" v-if="!filterSelected">
								<label>Nach einem Spieler suchen (min. 3 Zeichen)
									<input @keydown.enter=" findPlayer(searchText)" type="text" name="searchterm" value maxlength="100" class="input-group-field" v-model="searchText">
								</label>
								<label>Nach einem Club suchen (min. 3 Zeichen)
									<input @keydown.enter=" findTeam(searchText)" type="text" name="searchterm" value maxlength="100" class="input-group-field" v-model="searchTextTeam">
								</label>
							</div>
						</div>
					</div>
				</div>

				<div class="large-4 medium-4 cell">
					<div class="primary callout">
						<p><b><span class="badge dgl">3</span> Suche starten</b></p>
						<a href="#" class="button" @click="filterSelected ? findLiga() : searchText != '' ? findPlayer(searchText) : findTeam(searchTextTeam)">Ergebnisse anzeigen</a>
					</div>
				</div>
			</div>

			<div class="grid-x grid-padding-x ion-margin" v-show="leagueResult && leagueResult.length <= 0 && !searchFound">
				<div class="large-12 cell">
					<h4 class="white uppercase caption" style="margin-left: 15px;color: red;"><b>Keine Daten gefunden</b></h4>
					<h5 class="white caption" style="margin-left: 15px;color: red;"><b>Tipp: Bei der Suche nach ganzen Spielern oder Clubs muss die Schreibweise der Namen ganz genau beachtet werden (z.B. Golfclub, Golf-Club, Golf Club). Es kann helfen nach nur einem Teilbereich des Namens zu suchen. </b></h5>
				</div>
			</div>
			<div class="grid-x grid-padding-x" v-show="leagueResult && leagueResult.length > 0">
				<div class="large-12 cell">
					<h4 class="white uppercase caption" style="margin-left: 15px;"><b>Suchergebnis</b><b class="cursor" @click="pushLink(null)">&nbsp;(x)</b></h4>
				</div>
			</div>

			<div class="grid-x grid-padding-x" v-show="leagueResult && leagueResult.length > 0">
				<div class="large-12 cell">
					<div class="primary callout">
						<h6 class="uppercase dgl_blue cursor" v-for="(league, i) in leagueResult" :key="i" @click="pushLink(league, true)"><b>{{ league.name }}: <span class="warning">{{ league.foundName }}</span></b></h6>
					</div>
				</div>
			</div>

			<div class="grid-x grid-padding-x" v-if="(!teamScoresSelected || teamScoresSelected.length == 0) && leagueID != 0 && !getDGLTeamScoreLoading">
				<div class="large-12 cell">
					<ion-item>
						<h4 class="white caption" style="margin-right: 15px;"><b>Keine Daten vorhanden</b></h4>
					</ion-item>
				</div>
			</div>

			<div class="grid-x grid-padding-x" id="ankerMatchplay" v-if="leagueResult.length <= 0 && teamScoresSelected && teamScoresSelected.length > 0">
				<div class="ion-no-margin" style="margin: 0px;">
					<ion-item class="ion-no-margin">
						<h4 slot="start" class="white uppercase caption" style="margin-right: 15px;"><b>{{ scoringMode == 3 ? leagueinfo : leagueinfo + ' - ' + matchDays.find(f => f.id == selectedMatchDay)?.name }}</b></h4>
					</ion-item>
					<ion-item>
						<a  class="button secondary" @click="refreshScore()" style="margin-top: 0px;margin-bottom: 10px;zoom:1.4;padding: 0.2em 0.2em"><ion-icon :icon="refreshOutline" style="--ionicon-stroke-width: 64px;font-weight: bold;margin-top: 0px;zoom:1.4;vertical-align:middle;"></ion-icon><span style="font-size: 0.7rem;margin: 0px !important;">&nbsp;(Autom. alle 5 min.)&nbsp;</span></a>
						<a v-if="lid == 0 && pid == 0" class="button secondary" @click="openLink()" style="margin-left: 5px; margin-top: 0px;margin-bottom: 10px;zoom:1.4;padding: 0.2em 0.2em"><ion-icon :icon="openOutline" style="--ionicon-stroke-width: 64px;font-weight: bold;margin-top: 0px;zoom:1.4;vertical-align:middle;"></ion-icon><span style="font-size: 0.7rem;margin: 0px !important;">&nbsp;Staffel-Link&nbsp;</span></a>
					</ion-item>
					<marquee-text  v-if="currentTicker && currentTicker.tickerText != ''" style="margin-left: 20px;" class="ticker-text white caption" :key="currentTicker.id" :duration="12" :repeat="15" :reverse="false">{{ currentTicker.tickerText }}&nbsp; &nbsp; &nbsp; 
              		</marquee-text>
					<div>
					  <select v-if="lid == 0 && pid == 0 && leagueinfo.includes('Final Four')" v-model="selectedMatchDay" style="margin-left: 16px;width: auto;max-width: 300px;">
						<option :value="day.id" v-for="(day, i) in matchDays" :key="i">{{ day.name.split(' ').slice(4).join(' ').replaceAll('_', ' ') }}</option>
						</select>
					</div>


				</div>
			</div>

			<div class="grid-x grid-padding-x" v-if="leagueResult.length <= 0 && teamScoresSelected && teamScoresSelected.length > 0">
				<div class="large-12 cell">
					<div class="primary callout">
						<h6 class="dgl_blue" v-if="scoringMode != 3 && matchDays"><b>{{ matchDays.find(f => f.id == selectedMatchDay)?.tdate }}<br> im {{ matchDays.find(f => f.id == selectedMatchDay)?.nameshort.replaceAll('_', ' ') }}</b></h6>
						<ion-grid v-if="scoringMode != 3">
							<ion-row>
								<ion-col size="auto"><select v-if="scoringMode != 3" name="team" style="width: auto;" class="uk-select" v-model="selectedViewType">
									<option :value="0">Team</option>
									<option :value="1">Einzel</option>
								</select></ion-col>
								<ion-col size="auto"><select v-if="singleScoresRounds.length > 1 && scoringMode != 3 && selectedViewType == 1" name="team" style="width: auto;" class="uk-select" v-model="selectedSingleRound">
									<option v-for="(round, index) in singleScoresRounds" :key="index" :value="round.id">{{ 'Runde ' + round.roundIndex + ' - ' + round.playForm }}</option>
								</select></ion-col>
								<ion-col size="auto">
									<ion-button v-if="teamScores.find(f => f.rounds.find(f => f.showBlitz == true)) != undefined && blitzScores.find(f => f.totalScore != 0) && scoringMode != 3 && teamScoresSelected && teamScoresSelected.length > 0" @click="openLiveTable()" color="danger" style="background-color: #eb445a;--box-shadow: 0px;">
										Blitztabelle
									</ion-button>
								</ion-col>

								<ion-col size="auto"><a  v-if="teamScores.find(f => f.rounds.find(f => f.showBlitz == true)) == undefined && scoringMode != 3 && teamScoresSelected.length > 0" class="button" @click="openInNewTab(golfdeLink)" style="margin-top: 0px;">Zur Ergebnistabelle</a></ion-col>
							</ion-row>
						</ion-grid>

						<table v-if="scoringMode != 3 && selectedViewType == 0" class="tabelle-dgl">
							<tbody>
								<tr style="border-bottom: none!important;">
									<th>Rang | Team</th>
									<th class="text-center">+/-</th>
								</tr>
								<template v-for="(team, i) in teamScoresSelected" :key="i">
									<tr class="cursor" @click="team.showDetails == undefined ? team.showDetails = true : team.showDetails = !team.showDetails" :style="team.showDetails == true ? 'background-color: green;' : ''">
										<td>
											<ion-grid class="ion-no-margin ion-no-padding">
												<ion-row>
													<ion-col size="1" class="fixedcol">
														<span v-if="i%2==0" class="float-left badge dgl">{{ i + 1 }}</span>
														<span v-if="i%2!=0" class="float-left badge dgl-table">{{ i + 1 }}</span>
													</ion-col>
													<ion-col size="1" class="fixedcol"><div class="clublogo"><img src="" alt></div></ion-col>
													<ion-col size="10"><b :style="team.showDetails == true ? 'color: white;' : ''">{{ team.name }}</b></ion-col>
												</ion-row>
											</ion-grid>
										</td>
										<td class="text-center">
											<b :style="team.showDetails == true ? 'color: white;' : ''">{{ getPlusSign(team.totalScore) }}</b>
										</td>
									</tr>
										<tr v-if="team.showDetails == true" style="background-color: white;">
											<td colspan="2">
											<table class="tabelle-dgl padding-top_0">
												<tbody>
													<tr v-if="i == 0" class="ion-no-padding ion-no-margin bg-white">
														<td colspan="6" class="ion-no-padding ion-no-margin bg-white">
															<div style="text-align:center;" data-header-ad-wrapper>
																<VueScriptComponent script='<script type="text/javascript" src="https://ad1.adfarm1.adition.com/js?wp_id=4804266&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_39}"></script>' />
															</div>
														</td>
													</tr>
													<tr v-if="i == 1"  class="ion-no-padding ion-no-margin bg-white">
														<td colspan="6" class="ion-no-padding ion-no-margin bg-white">
																<div style="text-align:center;" data-header-ad-wrapper>
																	<VueScriptComponent script='<script type="text/javascript" src="https://ad1.adfarm1.adition.com/js?wp_id=4804267&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_39}"></script>' />
																</div>
														</td>
													</tr>
													<tr v-if="i == 2"  class="ion-no-padding ion-no-margin bg-white">
														<td colspan="6" class="ion-no-padding ion-no-margin">
																<div style="text-align:center;" data-header-ad-wrapper>
																	<VueScriptComponent script='<script type="text/javascript" src="https://ad1.adfarm1.adition.com/js?wp_id=4804268&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_39}"></script>' />
																</div>
														</td>
													</tr>
													<tr v-if="i == 3"  class="ion-no-padding ion-no-margin bg-white">
														<td colspan="6" class="ion-no-padding ion-no-margin">
																<div style="text-align:center;" data-header-ad-wrapper>
																	<VueScriptComponent script='<script type="text/javascript" src="https://ad1.adfarm1.adition.com/js?wp_id=4804269&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_39}"></script>' />
																</div>
														</td>
													</tr>
													<tr v-if="i == 4"  class="ion-no-padding ion-no-margin bg-white">
														<td colspan="6" class="ion-no-padding ion-no-margin">
																<div style="text-align:center;" data-header-ad-wrapper>
																	<VueScriptComponent script='<script type="text/javascript" src="https://ad1.adfarm1.adition.com/js?wp_id=4804270&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_39}"></script>' />
																</div>
														</td>
													</tr>
													<template v-for="round in team.rounds.filter(f => f.showRound == undefined || f.showRound == true)" :key="round">
														<tr v-if="round.players.length > 0" @click="team.showDetails = !team.showDetails">
															<th colspan="5" class="padding-top_2 padding-left_0" >
																<span class="category">Runde {{ round.roundIndex  }} -  {{ round.playForm  }}</span>
															</th>
														</tr>
														<tr>
															<th v-if="leagueinfo && leagueinfo.startsWith('Herren')">Spieler</th>
															<th v-if="leagueinfo && leagueinfo.startsWith('Damen')">Spielerin</th>
															<th v-if="!isPlatform('mobile')" class="text-center">HCPI&#8482;</th>
															<th class="text-center">+/-</th>
															<th class="text-center">Nach</th>
															<th class="text-center">Brutto</th>
														</tr>
														<template v-for="(player, i) in round.players" :key="i">
														<tr :class="player.isStreicher ? 'bg_blue cursor streicher' : 'bg_blue cursor'" v-if="player.partnerNum < 2" @click="player.showScorecard = !player.showScorecard">
															<td>{{ isPlatform('mobile') ? player.nameshort : player.name }}<span v-if="player.partnerNum == 1"><br>{{ isPlatform('mobile') ? round.players[i+1].nameshort : round.players[i+1].name  }}</span></td>

															<td v-if="!isPlatform('mobile')" class="text-center">
																<span class="text-right">{{ player.hcp }}<span v-if="player.partnerNum == 1"><br>{{ round.players[i+1].hcp  }}</span></span>
															</td>
															<td class="text-center">
															<span class="text-right">{{ player.partnerNum == 2 ? '' : player.showTeetime != '' ? '-' : player.isStreicher ? '(' + getPlusSign(player.score) + ')' : getPlusSign(player.score) }}</span>
															</td>
															<td class="text-center">
															<span class="text-right">{{ player.partnerNum == 2 ? '' : player.showTeetime != '' ? player.showTeetime : player.playedHoles }}</span>
															</td>
															<td class="text-center">
															<span class="text-right">{{ player.partnerNum == 2 ? '' : player.showTeetime != '' ? '-' : player.playedHoles == 'F' ? player.isStreicher ? '(' + player.strokes + ')' : player.strokes : '-' }}</span>
															</td>
														</tr>
														<tr v-if="player.showScorecard" class="scorecard-row-noborder">
															<td colspan="10">
																{{ player.clubName }}
															</td>
														</tr>
														<tr v-if="player.showScorecard" class="scorecard-row-noborder">
															<td colspan="6" @click="player.showScorecard = !player.showScorecard">
															<table class="scorecard-row">
																<tbody class="scorecard-row">
																	<tr class="scorecard-row">
																		<td class="scorecard-row"><span>Loch</span></td>
																		<td  v-for="numh in 9" :key="numh" class="scorecard-row"><span><span style="color: white">0</span>{{ numh }}</span></td>
																		<td class="scorecard-row"><span>OUT</span></td>
																		<td class="scorecard-row"><span class="white">TOT</span></td>
																	</tr>
																	<tr class="scorecard-row">
																		<td class="scorecard-row"><span class="chakra-text css-1v4gmez">Par</span></td>
																		<td class="scorecard-row" v-for="par in player.pars.slice(0,9)" :key="par"><span>{{ par }}</span></td>
																		
																		<td class="scorecard-row"><span>{{ player.pars.slice(0,9).reduce((a, b) => a + b, 0) }}</span></td>
																		<td class="scorecard-row"><span></span></td>
																	</tr>
																		
																	<tr class="scorecard-row">
																		<td class="scorecard-row"><span><b>R{{ round.roundIndex }}</b></span></td>
																		<td class="scorecard-row" v-for="(score, index) in player.scores.slice(0,9)" :key="index" :value="score">

																			<div v-if="getScoreClass(player.pars[index], player.scores[index]) =='birdie'" :style="score > 9 ? 'width: 1.45rem;' : ''" class="leaderboard__visual-par--bi leaderboard__visual-par">{{ score == 0 ? '-' : score }}</div>

																			<div v-if="getScoreClass(player.pars[index], player.scores[index]) =='par'">{{ score == 0 ? '-' : score }}</div>

																			<div v-if="getScoreClass(player.pars[index], player.scores[index]) =='bogey'" :style="score > 9 ? 'width: 1.45rem;' : ''" class="leaderboard__visual-par--bo leaderboard__visual-par">{{ score == 0 ? '-' : score }}</div>

																			<div v-if="getScoreClass(player.pars[index], player.scores[index]) =='doublebogey'" :style="score > 9 ? 'width: 1.45rem;' : ''" class="leaderboard__visual-par--db leaderboard__visual-par">{{ score == 0 ? '-' : score }}</div>

																			<div v-if="getScoreClass(player.pars[index], player.scores[index]) =='eagle'" :style="score > 9 ? 'width: 1.45rem;' : ''" class="leaderboard__visual-par--ea leaderboard__visual-par">{{ score == 0 ? '-' : score }}</div>

																			<span v-if="getScoreClass(player.pars[index], player.scores[index]) =='par1'"><b>{{ score == 0 ? '-' : score }}</b></span>

																		</td>
																		<td class="scorecard-row"><span><b>{{ player.playedHoles == 'F' ? player.scores.slice(0,9).reduce((a, b) => a + b, 0) : '-' }}</b></span></td>
																		<td class="scorecard-row"><span></span></td>
																	</tr>
																	<tr class="scorecard-row">
																		<td class="scorecard-row"><span><b>+/-</b></span></td>
																		<td class="scorecard-row" v-for="status in player.status.slice(0,9)" :key="status"><span><b>{{ status == -100 ? '' : getPlusSign(status) }}</b></span></td>
																		<td class="scorecard-row"><span></span></td>
																	</tr>
																	<tr v-if="player.showScorecard" class="scorecard-row-boldborder"></tr>
																</tbody>
															</table>
															<table class="scorecard-row" style="margin-bottom: 0px;">
																<tbody class="scorecard-row">
																	<tr class="scorecard-row">
																		<td class="scorecard-row"><span>Loch</span></td>
																		<td  v-for="numh in 9" :key="numh" class="scorecard-row"><span>{{ numh + 9 }}</span></td>
																		<td class="scorecard-row"><span>&nbsp;&nbsp;IN&nbsp;&nbsp;</span></td>
																		<td class="scorecard-row"><span>TOT</span></td>
																	</tr>
																	<tr class="scorecard-row">
																		<td class="scorecard-row"><span class="chakra-text css-1v4gmez">Par</span></td>
																		<td class="scorecard-row" v-for="par in player.pars.slice(9,18)" :key="par"><span>{{ par }}</span></td>
																		
																		<td class="scorecard-row"><span>{{ player.pars.slice(9,18).reduce((a, b) => a + b, 0) }}</span></td>
																		<td class="scorecard-row"><span>{{ player.pars.reduce((a, b) => a + b, 0) }}</span></td>
																	</tr>
																		
																	<tr class="scorecard-row">
																		<td class="scorecard-row"><span><b>R{{ round.roundIndex }}</b></span></td>
																		<td class="scorecard-row" v-for="(score, index) in player.scores.slice(9,18)" :key="index" :value="score">

																			<div v-if="getScoreClass(player.pars[index+9], player.scores[index+9]) =='birdie'" :style="score > 9 ? 'width: 1.45rem;' : ''" class="leaderboard__visual-par--bi leaderboard__visual-par">{{ score == 0 ? '-' : score }}</div>

																			<div v-if="getScoreClass(player.pars[index+9], player.scores[index+9]) =='par'">{{ score == 0 ? '-' : score }}</div>

																			<div v-if="getScoreClass(player.pars[index+9], player.scores[index+9]) =='bogey'" :style="score > 9 ? 'width: 1.45rem;' : ''" class="leaderboard__visual-par--bo leaderboard__visual-par">{{ score == 0 ? '-' : score }}</div>

																			<div v-if="getScoreClass(player.pars[index+9], player.scores[index+9]) =='doublebogey'" :style="score > 9 ? 'width: 1.45rem;' : ''" class="leaderboard__visual-par--db leaderboard__visual-par">{{ score == 0 ? '-' : score }}</div>

																			<div v-if="getScoreClass(player.pars[index+9], player.scores[index+9]) =='eagle'" :style="score > 9 ? 'width: 1.45rem;' : ''" class="leaderboard__visual-par--ea leaderboard__visual-par">{{ score == 0 ? '-' : score }}</div>

																			<span v-if="getScoreClass(player.pars[index+9], player.scores[index+9]) =='par1'"><b>{{ score == 0 ? '-' : score }}</b></span>

																		</td>
																		<td class="scorecard-row"><span>{{ player.scores.slice(9,18).reduce((a, b) => a + b, 0)}}</span></td>
																		<td class="scorecard-row"><span><b>{{ player.playedHoles == 'F' ? player.strokes : '-' }}</b></span></td>
																	</tr>
																	<tr class="scorecard-row">
																		<td class="scorecard-row"><span><b>+/-</b></span></td>
																		<td class="scorecard-row" v-for="status in player.status.slice(9,18)" :key="status"><span><b>{{ status == -100 ? '' : getPlusSign(status) }}</b></span></td>
																		<td class="scorecard-row"><span></span></td>
																		<td class="scorecard-row"><span><b>{{ getPlusSign(player.score) }}</b></span></td>
																	</tr>
																	<tr class="scorecard-row" style="text-align: right;">
																		<td colspan="20" style="text-align: right;">
																			<div class="leaderboard__key--hbh">
																				<span class="leaderboard__key--item leaderboard__key--eagle">Eagle oder besser</span>
																				<span style="margin-left: 5px;" class="leaderboard__key--item leaderboard__key--birdie">Birdie</span>
																				<span style="margin-left: 5px;" class="leaderboard__key--item leaderboard__key--bogey">Bogey</span>
																				<span style="margin-left: 5px;" class="leaderboard__key--item leaderboard__key--dbogey">Double Bogey +</span>
																			</div>
																		</td>
																	</tr>
																</tbody>
															</table>
															</td>
														</tr>
														<tr v-if="player.showScorecard"></tr>
														<tr v-if="player.showScorecard"></tr>
														</template>
														<tr></tr>
														<tr>
															<th>Gesamt</th>
															<th v-if="!isPlatform('mobile')" class="text-center"></th>
															<th class="text-center"><strong v-if="round.players.length > 0">{{ getSign(round.score) }}{{ round.score == 0 ? 'Par' : round.score }}</strong></th>
															<th class="text-center"></th>
															<th class="text-center"><strong v-if="round.players.length > 0 && round.players.filter(f => f.playedHoles != 'F').length == 0">{{ round.strokes }}</strong></th>
														</tr>
													</template>
													<span style="color: black;font-size: 12px;margin-left: 15px;font-weight: normal;">* Start Tee 10</span>
												</tbody>
											</table>
										 	</td>
										</tr>
										<tr v-if="team.showDetails == true" style="background-color: white;"></tr>
								</template>
							</tbody>
						</table>

						<table v-if="scoringMode != 3 && selectedViewType == 1" class="tabelle-dgl">  <!-- single Scores -->
							<table class="ergebnisboard padding-top_0">
								<tbody>
									<tr>
										<th v-if="leagueinfo && leagueinfo.startsWith('Herren')">Spieler</th>
										<th v-if="leagueinfo && leagueinfo.startsWith('Damen')">Spielerin</th>
										<th v-if="!isPlatform('mobile')">Club</th>
										<th v-if="!isPlatform('mobile')" class="text-center">HCPI&#8482;</th>
										<th class="text-center">+/-</th>
										<th class="text-center">Nach</th>
										<th class="text-center">Brutto</th>
									</tr>
									<template v-for="(player, i) in singleScoresPlayers" :key="i">
									<tr class="bg_blue cursor" v-if="player.partnerNum < 2" @click="player.showScorecard = !player.showScorecard">
										<td>{{ isPlatform('mobile') ? player.nameshort : player.name }}<span v-if="player.partnerNum == 1"><br>{{ isPlatform('mobile') ? singleScoresPlayers[i+1].nameshort : singleScoresPlayers[i+1].name  }}</span></td>
										<td v-if="!isPlatform('mobile')">{{ player.clubName }}</td>

										<td v-if="!isPlatform('mobile')" class="text-center">
											<span class="text-right">{{ player.hcp }}<span v-if="player.partnerNum == 1"><br>{{ singleScoresPlayers[i+1].hcp  }}</span></span>
										</td>
										<td class="text-center">
										<span class="text-right">{{ player.partnerNum == 2 ? '' : player.showTeetime != '' ? '-' : player.isStreicher ? '(' + getPlusSign(player.score) + ')' : getPlusSign(player.score) }}</span>
										</td>
										<td class="text-center">
										<span class="text-right">{{ player.partnerNum == 2 ? '' : player.showTeetime != '' ? player.showTeetime : player.playedHoles }}</span>
										</td>
										<td class="text-center">
										<span class="text-right">{{ player.partnerNum == 2 ? '' : player.showTeetime != '' ? '-' : player.playedHoles == 'F' ? player.isStreicher ? '(' + player.strokes + ')' : player.strokes : '-' }}</span>
										</td>
									</tr>
									<tr v-if="player.showScorecard"></tr>
									<tr v-if="player.showScorecard" class="scorecard-row-noborder">
										<td colspan="6" @click="player.showScorecard = !player.showScorecard">
										<table class="scorecard-row">
											<tbody class="scorecard-row">
												<tr v-if="player.showScorecard" class="scorecard-row-noborder">
													<td colspan="10" class="ion-no-margin">
														{{ player.clubName }}
													</td>
												</tr>

												<tr class="scorecard-row">
													<td class="scorecard-row"><span>Loch</span></td>
													<td  v-for="numh in 9" :key="numh" class="scorecard-row"><span>{{ numh }}</span></td>
													<td class="scorecard-row"><span>OUT</span></td>
													<td class="scorecard-row"><span class="white">TOT</span></td>
												</tr>
												<tr class="scorecard-row">
													<td class="scorecard-row"><span class="chakra-text css-1v4gmez">Par</span></td>
													<td class="scorecard-row" v-for="par in player.pars.slice(0,9)" :key="par"><span>{{ par }}</span></td>
													
													<td class="scorecard-row"><span>{{ player.pars.slice(0,9).reduce((a, b) => a + b, 0) }}</span></td>
													<td class="scorecard-row"><span></span></td>
												</tr>
													
												<tr class="scorecard-row">
													<td class="scorecard-row"><span><b>R{{ singleScoresRounds.find(f => f.id == selectedSingleRound).roundIndex }}</b></span></td>
													<td class="scorecard-row" v-for="(score, index) in player.scores.slice(0,9)" :key="index" :value="score">

														<div v-if="getScoreClass(player.pars[index], player.scores[index]) =='birdie'" :style="score > 9 ? 'width: 1.45rem;' : ''" class="leaderboard__visual-par--bi leaderboard__visual-par">{{ score == 0 ? '-' : score }}</div>

														<div v-if="getScoreClass(player.pars[index], player.scores[index]) =='par'">{{ score == 0 ? '-' : score }}</div>

														<div v-if="getScoreClass(player.pars[index], player.scores[index]) =='bogey'" :style="score > 9 ? 'width: 1.45rem;' : ''" class="leaderboard__visual-par--bo leaderboard__visual-par">{{ score == 0 ? '-' : score }}</div>

														<div v-if="getScoreClass(player.pars[index], player.scores[index]) =='doublebogey'" :style="score > 9 ? 'width: 1.45rem;' : ''" class="leaderboard__visual-par--db leaderboard__visual-par">{{ score == 0 ? '-' : score }}</div>

														<div v-if="getScoreClass(player.pars[index], player.scores[index]) =='eagle'" :style="score > 9 ? 'width: 1.45rem;' : ''" class="leaderboard__visual-par--ea leaderboard__visual-par">{{ score == 0 ? '-' : score }}</div>

														<span v-if="getScoreClass(player.pars[index], player.scores[index]) =='par1'"><b>{{ score == 0 ? '-' : score }}</b></span>

													</td>
													<td class="scorecard-row"><span><b>{{ player.playedHoles == 'F' ? player.scores.slice(0,9).reduce((a, b) => a + b, 0) : '-' }}</b></span></td>
													<td class="scorecard-row"><span></span></td>
												</tr>
												<tr class="scorecard-row">
													<td class="scorecard-row"><span><b>+/-</b></span></td>
													<td class="scorecard-row" v-for="status in player.status.slice(0,9)" :key="status"><span><b>{{ status == -100 ? '' : getPlusSign(status) }}</b></span></td>
													<td class="scorecard-row"><span></span></td>
												</tr>
												<tr v-if="player.showScorecard" class="scorecard-row-boldborder"></tr>
											</tbody>
										</table>
										<table class="scorecard-row">
											<tbody class="scorecard-row">
												<tr class="scorecard-row">
													<td class="scorecard-row"><span>Loch</span></td>
													<td  v-for="numh in 9" :key="numh" class="scorecard-row"><span>{{ numh + 9 }}</span></td>
													<td class="scorecard-row"><span>&nbsp;&nbsp;IN&nbsp;&nbsp;</span></td>
													<td class="scorecard-row"><span>TOT</span></td>
												</tr>
												<tr class="scorecard-row">
													<td class="scorecard-row"><span class="chakra-text css-1v4gmez">Par</span></td>
													<td class="scorecard-row" v-for="par in player.pars.slice(9,18)" :key="par"><span>{{ par }}</span></td>
													
													<td class="scorecard-row"><span>{{ player.pars.slice(9,18).reduce((a, b) => a + b, 0) }}</span></td>
													<td class="scorecard-row"><span>{{ player.pars.reduce((a, b) => a + b, 0) }}</span></td>
												</tr>
													
												<tr class="scorecard-row">
													<td class="scorecard-row"><span><b>R{{ singleScoresRounds.find(f => f.id == selectedSingleRound).roundIndex }}</b></span></td>
													<td class="scorecard-row" v-for="(score, index) in player.scores.slice(9,18)" :key="index" :value="score">

														<div v-if="getScoreClass(player.pars[index+9], player.scores[index+9]) =='birdie'" :style="score > 9 ? 'width: 1.45rem;' : ''" class="leaderboard__visual-par--bi leaderboard__visual-par">{{ score == 0 ? '-' : score }}</div>

														<div v-if="getScoreClass(player.pars[index+9], player.scores[index+9]) =='par'">{{ score == 0 ? '-' : score }}</div>

														<div v-if="getScoreClass(player.pars[index+9], player.scores[index+9]) =='bogey'" :style="score > 9 ? 'width: 1.45rem;' : ''" class="leaderboard__visual-par--bo leaderboard__visual-par">{{ score == 0 ? '-' : score }}</div>

														<div v-if="getScoreClass(player.pars[index+9], player.scores[index+9]) =='doublebogey'" :style="score > 9 ? 'width: 1.45rem;' : ''" class="leaderboard__visual-par--db leaderboard__visual-par">{{ score == 0 ? '-' : score }}</div>

														<div v-if="getScoreClass(player.pars[index+9], player.scores[index+9]) =='eagle'" :style="score > 9 ? 'width: 1.45rem;' : ''" class="leaderboard__visual-par--ea leaderboard__visual-par">{{ score == 0 ? '-' : score }}</div>

														<span v-if="getScoreClass(player.pars[index+9], player.scores[index+9]) =='par1'"><b>{{ score == 0 ? '-' : score }}</b></span>

													</td>
													<td class="scorecard-row"><span>{{ player.scores.slice(9,18).reduce((a, b) => a + b, 0)}}</span></td>
													<td class="scorecard-row"><span><b>{{ player.playedHoles == 'F' ? player.strokes : '-' }}</b></span></td>
												</tr>
												<tr class="scorecard-row">
													<td class="scorecard-row"><span><b>+/-</b></span></td>
													<td class="scorecard-row" v-for="status in player.status.slice(9,18)" :key="status"><span><b>{{ status == -100 ? '' : getPlusSign(status) }}</b></span></td>
													<td class="scorecard-row"><span></span></td>
													<td class="scorecard-row"><span><b>{{ getPlusSign(player.score) }}</b></span></td>
												</tr>
												<tr class="scorecard-row" style="text-align: right;">
													<td colspan="20" style="text-align: right;">
														<div class="leaderboard__key--hbh">
															<span class="leaderboard__key--item leaderboard__key--eagle">Eagle oder besser</span>
															<span style="margin-left: 5px;" class="leaderboard__key--item leaderboard__key--birdie">Birdie</span>
															<span style="margin-left: 5px;" class="leaderboard__key--item leaderboard__key--bogey">Bogey</span>
															<span style="margin-left: 5px;" class="leaderboard__key--item leaderboard__key--dbogey">Double Bogey +</span>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
										</td>
									</tr>
									<tr v-if="player.showScorecard"></tr>
									<tr v-if="player.showScorecard"></tr>
									</template>
									<tr></tr>
								</tbody>
							</table>
						</table>

          <ion-grid class="ion-no-padding ion-no-margin" v-if="scoringMode == 3 && teamScoresSelected.length > 0 && selectedViewType == 0"> <!-- Matchplay View -->
            <ion-row class="ion-justify-content-center ion-no-padding ion-no-margin center-row">
                <ion-grid :fixed="true" class="ion-no-padding ion-no-margin" > 
				  <ion-row class="ion-no-padding ion-no-margin" >
					<h6 class="uppercase dgl_blue ion-no-margin" style="margin-left: 10px" v-if="matchDays"><b>{{ matchDays.find(f => f.id == selectedMatchDay)?.tdate }} IM {{ matchDays.find(f => f.id == selectedMatchDay)?.nameshort }}</b></h6>
				  </ion-row>
					
				  <ion-row v-if="lid != 0 && pid != 0 && (leagueinfo.startsWith('Final Four') || leagueinfo.startsWith('Aufstieg'))" class="ion-no-margin ion-no-padding">
					<h6 class="uppercase dgl_blue ion-no-margin" style="margin-left: 10px"><b>{{ matchDays.find(f => f.id == selectedMatchDay)?.name.split(' ').slice(4).join(' ').replaceAll('_', ' ')  }}</b></h6>
				  </ion-row>

				  <ion-row v-if="tableCategory == 3" class="ion-no-margin ion-no-padding">
					<h6 class="uppercase dgl_blue ion-no-margin" style="margin-left: 10px"><b>{{ teamScoresSelected[0].name }} - {{ teamScoresSelected[1].name }}</b></h6>
				  </ion-row>
    
				  <ion-row class="ion-justify-content-center ion-no-padding ion-no-margin center-row" style="margin-top: 20px">
					<div class="ScoringModule-tracker"><div class="ScoringModule-teams">
						<div class="Team" data-team="team1" data-guest="false">
							<div class="Team-flag">
								<picture><source :srcset="logoTeam1"><source :srcset="logoTeam1"><img :src="require('@/assets/images/' + teamScoresSelected[0]?.logoName)" alt="" loading="lazy" width="60"></picture>
							</div>
							<div class="Team-points">
								<div class="Team-points-text">{{ teamScoresSelected[0].name }}&nbsp;&nbsp;</div>
							</div>
						</div>
						<div class="Team" data-team="team2" data-guest="true">
							<div class="Team-flag"><picture><source :srcset="logoTeam2" type="image/webp"><source :srcset="logoTeam2"><img :src="require('@/assets/images/' + teamScoresSelected[1]?.logoName)" width="60" alt="" loading="lazy"></picture>
							</div>
							<div class="Team-points">
								<div style="text-align: right" class="Team-points-text">&nbsp;&nbsp;{{ teamScoresSelected[1].name }}</div>
							</div>
						</div>
					</div>
					<div class="ScoreTracker">
						<div class="ScoreTracker-bar">
							<div class="ScoreTrackerTeam" data-team="team1" data-guest="false" data-projected="true" :style="'width: ' + getPercent(0, true)"></div>
							<div class="ScoreTrackerTeam" data-team="team1" data-guest="false" data-projected="false" :style="'width: ' + getPercent(0, false)"></div>
							<div class="ScoreTracker-score" data-guest="false" data-color="white" v-if="teamScoresSelected[0] && teamScoresSelected[0].club1Total">{{ teamScoresSelected[0].club1Total.indexOf('.') > 0 ? Math.floor(teamScoresSelected[0].club1Total) : teamScoresSelected[0].club1Total}}<sup v-if="teamScoresSelected[0].club1Total.indexOf('.') > 0">1/2</sup></div>
							<div class="ScoreTrackerTeam" data-team="team2" data-guest="true" data-projected="true" :style="'width: ' + getPercent(1, true)"></div>
							<div class="ScoreTrackerTeam" data-team="team2" data-guest="true" data-projected="false" :style="'width: ' + getPercent(1, false)"></div>
							<div class="ScoreTracker-score" data-guest="true" data-color="white" v-if="teamScoresSelected[0] && teamScoresSelected[0].club2Total">{{ teamScoresSelected[0].club2Total.indexOf('.') > 0 ? Math.floor(teamScoresSelected[0].club2Total) : teamScoresSelected[0].club2Total}}<sup v-if="teamScoresSelected[0].club2Total.indexOf('.') > 0">1/2</sup></div><div :class="'ScoreTracker-lines' + gender"></div>
						</div>
					</div>
					<div class="ScoringModule-legend">
						<div class="ScoringModule-legend-item">
							<div class="ScoringModule-symbols">
								<div class="ScoringModule-symbol" data-team="team1"></div>
								<div class="ScoringModule-symbol" data-team="team2"></div>
							</div>
							<div class="ScoringModule-symbol-text">Beendete Matches</div>
						</div>
						<div class="ScoringModule-legend-item" data-projected="true">
							<div class="ScoringModule-symbols">
								<div class="ScoringModule-symbol" data-team="team1"></div>
								<div class="ScoringModule-symbol" data-team="team2"></div>
							</div>
							<div class="ScoringModule-symbol-text">Laufende Matches</div>
						</div>
					</div>
					</div>
					<tr class="ion-no-padding ion-no-margin bg-white">
						<td colspan="6" class="ion-no-padding ion-no-margin bg-white">
							<div style="text-align:center;" data-header-ad-wrapper>
								<VueScriptComponent script='<script type="text/javascript" src="https://ad1.adfarm1.adition.com/js?wp_id=4804266&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_39}"></script>' />
							</div>
						</td>
					</tr>

				  </ion-row>
                </ion-grid>
            </ion-row>

				<table v-for="round in teamScoresSelected[0].rounds" :key="round">
					<tr v-if="round.roundIndex == 1 && teamScoresSelected[0]?.rounds[0]?.players.length > 0"  class="ion-no-padding ion-no-margin bg-white">
						<td colspan="6" class="ion-no-padding ion-no-margin bg-white">
								<div style="text-align:center;" data-header-ad-wrapper>
									<VueScriptComponent script='<script type="text/javascript" src="https://ad1.adfarm1.adition.com/js?wp_id=4804267&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_39}"></script>' />
								</div>
						</td>
					</tr>
					<tr v-if="round.players.length > 0">
						<th colspan="5" class="padding-top_2 padding-left_0" >
							<span class="category">Runde {{ round.roundIndex  }} -  {{ round.playForm  }}</span>
						</th>
					</tr>

				<div class="ScoringModule-panel" data-panel-id="sunday-singles" data-visible="true" data-tab-panel="true">
					<div class="ScoringRound">
						<div class="ScoringRound-matches">
							<div class="Match" v-for="(player, i) in round.players" :key="i" data-completed="true" data-type="Singles" data-expanded="false">
								<div class="Match-content" data-content="true">
									<div class="Match-default" tabindex="0" role="button">
										<div class="Match-default-top">
											<div class="MatchTeam" :data-final="player.isCompleted == '(final)' ? true : false" data-host="true" data-team="team1" :data-winner="player.score > player.scoreOpponent ? true : false" :data-tied="player.score == player.scoreOpponent ? true : false">
												<div v-if="player.score > player.scoreOpponent" class="winBkgd" :data-tied="player.score == player.scoreOpponent ? true : false"></div>
												<div class="MatchPlayer">
													<div class="MatchPlayer-name">
														<div class="MatchPlayer-firstName">{{ player.firstname }}</div>
														<div class="MatchPlayer-lastName">{{ player.lastname }}</div>
													</div>
													<div v-if="round.playForm == 'Vierer'" class="MatchPlayer-name">
														<div class="MatchPlayer-firstName">{{ player.name.split('/').length > 1 ? player.name.split('/')[1].trim().split('  ')[0].trim() : '' }}</div>
														<div class="MatchPlayer-lastName">{{ player.name.split('/').length > 1 ? player.name.split('/')[1].trim().split('  ')[1].trim() : '' }}</div>
													</div>
												</div>
											</div>
											<div class="Match-topCenter">
												<div class="MatchInfo">
													<div class="MatchInfo-wrapper"><span class="MatchInfo-number">Match {{ i+1 }} -</span><span class="MatchInfo-progress">{{ player.isCompleted == '(final)' && player.holesPlayed > 0 ? ('Final' + (player.startTee  != "1" ? ' *' : '' )) : player.playedHoles > 0 ? player.holesPlayed : player.isCompleted }}</span>
													</div>
												</div>
												<div class="Match-topScore" data-cta="false">
													<div class="MatchScore" data-score="true">
														<div :class="player.score == player.scoreOpponent ? 'MatchScore-tied' : 'MatchScore-num'" :data-final="player.isCompleted == '(final)' ? true : false" :data-advantage="player.score > player.scoreOpponent ? 'team1' : player.score < player.scoreOpponent ? 'team2' : ''"><span :class="player.scoreLabel == 'geteilt' ? 'MatchScore-large-tie' : 'MatchScore-large'">{{ player.scoreLabel }}</span>
														</div>
													</div>
												</div>
												<div class="Match-cta-mobile"></div>
											</div>
											<div class="MatchTeam" :data-final="player.isCompleted == '(final)' ? true : false" data-host="false" data-team="team2" :data-winner="player.score < player.scoreOpponent ? true : false" :data-tied="player.score == player.scoreOpponent ? true : false">
												<div v-if="player.score < player.scoreOpponent" class="winBkgd" :data-tied="player.score == player.scoreOpponent ? true : false"></div>
												<div class="MatchPlayer">
													<div class="MatchPlayer-name">
														<div class="MatchPlayer-firstName">{{ player.opponentFirstname }}</div>
														<div class="MatchPlayer-lastName">{{ player.opponentLastname }}</div>
													</div>

													<div v-if="round.playForm == 'Vierer' && player.opponentName" class="MatchPlayer-name">
														<div class="MatchPlayer-firstName">{{ player.opponentName.split('/').length > 1 ? player.opponentName.split('/')[1].trim().split('  ')[0].trim() : '' }}</div>
														<div class="MatchPlayer-lastName">{{ player.opponentName.split('/').length > 1 ? player.opponentName.split('/')[1].trim().split('  ')[1].trim() : '' }}</div>
													</div>

												</div>
											</div>
										</div>
										<div class="Match-default-bottom">
											<div class="Match-bottomCenter">
												<div class="MatchHoles">
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[0] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[0] == 1 ? 'team1' : player.scores[0] == 2 ? 'team2' : player.scores[0] == -1 ? 'tie' : ''">1</div>
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[1] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[1] == 1 ? 'team1' : player.scores[1] == 2 ? 'team2' : player.scores[1] == -1 ? 'tie' : ''">2</div>
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[2] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[2] == 1 ? 'team1' : player.scores[2] == 2 ? 'team2' : player.scores[2] == -1 ? 'tie' : ''">3</div>
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[3] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[3] == 1 ? 'team1' : player.scores[3] == 2 ? 'team2' : player.scores[3] == -1 ? 'tie' : ''">4</div>
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[4] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[4] == 1 ? 'team1' : player.scores[4] == 2 ? 'team2' : player.scores[4] == -1 ? 'tie' : ''">5</div>
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[5] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[5] == 1 ? 'team1' : player.scores[5] == 2 ? 'team2' : player.scores[5] == -1 ? 'tie' : ''">6</div>
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[6] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[6] == 1 ? 'team1' : player.scores[6] == 2 ? 'team2' : player.scores[6] == -1 ? 'tie' : ''">7</div>
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[7] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[7] == 1 ? 'team1' : player.scores[7] == 2 ? 'team2' : player.scores[7] == -1 ? 'tie' : ''">8</div>
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[8] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[8] == 1 ? 'team1' : player.scores[8] == 2 ? 'team2' : player.scores[8] == -1 ? 'tie' : ''">9</div>
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[9] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[9] == 1 ? 'team1' : player.scores[9] == 2 ? 'team2' : player.scores[9] == -1 ? 'tie' : ''">10</div>
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[10] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[10] == 1 ? 'team1' : player.scores[10] == 2 ? 'team2' : player.scores[10] == -1 ? 'tie' : ''">11</div>
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[11] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[11] == 1 ? 'team1' : player.scores[11] == 2 ? 'team2' : player.scores[11] == -1 ? 'tie' : ''">12</div>
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[12] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[12] == 1 ? 'team1' : player.scores[12] == 2 ? 'team2' : player.scores[12] == -1 ? 'tie' : ''">13</div>
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[13] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[13] == 1 ? 'team1' : player.scores[13] == 2 ? 'team2' : player.scores[13] == -1 ? 'tie' : ''">14</div>
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[14] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[14] == 1 ? 'team1' : player.scores[14] == 2 ? 'team2' : player.scores[14] == -1 ? 'tie' : ''">15</div>
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[15] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[15] == 1 ? 'team1' : player.scores[15] == 2 ? 'team2' : player.scores[15] == -1 ? 'tie' : ''">16</div>
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[16] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[16] == 1 ? 'team1' : player.scores[16] == 2 ? 'team2' : player.scores[16] == -1 ? 'tie' : ''">17</div>
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[17] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[17] == 1 ? 'team1' : player.scores[17] == 2 ? 'team2' : player.scores[17] == -1 ? 'tie' : ''">18</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- <button class="Match-toggler" data-toggler="true"><span class="sr-only">Expand</span></button>-->
							</div>
						</div>
					</div>
				</div>
				</table>
<!--            <ion-row class="ion-justify-content-center ion-no-padding ion-no-margin center-row"  v-for="round in teamScoresSelected[0].rounds" :key="round">
                <ion-grid :fixed="true"> 
                  <ion-row>
                    <div class="roundInfo">Runde {{ round.roundIndex  }} -  {{ round.playForm  }}</div>
                  </ion-row>
                  <ion-row style="font-weight: normal;border-bottom: solid;border-width: 1px;">
                    <ion-col style="text-align: right;" class="centercol">Spieler</ion-col>
                    <ion-col class="centercol">Punkte</ion-col>
                    <ion-col class="centercol">Ergebnis</ion-col>
                    <ion-col class="centercol">Punkte</ion-col>
                    <ion-col style="text-align: left;" class="centercol">Spieler</ion-col>
                  </ion-row>
                   <ion-row style="font-weight: normal;border-bottom: solid;border-width: 1px;" class="cursor" v-for="player in round.players" :key="player" @click="player.showScorecard = !player.showScorecard">
                    <ion-col style="text-align: right;" :class="player.score > player.scoreOpponent ? 'centercol blue bold' : 'centercol'"> {{ player.name }}</ion-col>
                    <ion-col :class="player.score > player.scoreOpponent ? 'centercol blue bold' : 'centercol'"> {{ player.score }}</ion-col>
                    <ion-col class="centercol">{{ player.scoreLabel }}<br v-if="player.isCompleted != ''">{{ player.isCompleted }}</ion-col>
                    <ion-col :class="player.score < player.scoreOpponent ? 'centercol blue bold' : 'centercol'"> {{ player.scoreOpponent }}</ion-col>
                    <ion-col style="text-align: left;" :class="player.score < player.scoreOpponent ? 'centercol blue bold' : 'centercol'"> {{ player.opponentName }}</ion-col>
                    <ion-row v-if="player.showScorecard">
                      <table class="playerScore matchplay">
                        <tbody>
                          <tr>
                            <td>Loch</td>
                            <td v-for="i in 18" :key="i">{{ i }}</td>
                          </tr>
                          <tr>
                            <td>{{ player.name }}</td>
                            <td v-for="label in player.scoresLabel" :key="label">{{ label != 'AS' && label != '--' ? label : '' }}</td>
                          </tr>
                          <tr class="as">
                            <td></td>
                            <td v-for="label in player.scoresLabel" :key="label">{{ label == 'AS' || label == '--' ? label : '' }}</td>
                          </tr>
                          <tr>
                            <td>{{ player.opponentName }}</td>
                            <td v-for="label in player.scoresLabelOpponent" :key="label">{{ label != 'AS' && label != '--' ? label : '' }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </ion-row>
                  </ion-row>
                </ion-grid>
            </ion-row>-->
          </ion-grid>



					</div>
				</div>
			</div>
		</div>

				<!-- <ion-grid class="ion-margin">
					<ion-row>
						<ion-col size="auto">
						<ion-searchbar class="ion-no-margin search-input" :search-icon="searchCircle" placeholder="Spieler suchen" v-model="searchText" @input="findPlayer($event.target.value)"></ion-searchbar>
						</ion-col>
						<ion-col size="auto">
						<ion-searchbar class="ion-no-margin search-input" :search-icon="searchCircle" placeholder="Mannschaft suchen" v-model="searchTextTeam" @input="findTeam($event.target.value)"></ion-searchbar>
						</ion-col>
					</ion-row>
				</ion-grid>

				<ion-item v-show="leaguesFound && leaguesFound.length > 0">
				<ion-list>
					<ion-item class="ion-text-wrap" v-for="(league, i) in leaguesFound" :key="i" @click="pushLink(league)" lines="none">
					<ion-label class="table_home cursor ion-text-wrap" @click="pushLink(league)">{{ league.name }}</ion-label>
					</ion-item>
				</ion-list>
				</ion-item>
				<hr>
				<div class="grid">
					<div class="grid_12">
					<div class="col_12 visible">
						<div class="grid_6 pr_0_5" v-for="(structure, i) in structureFound" :key="i" :value="structure">
						<h3 class="h3_headline"><a href="#">{{ structure.header }}</a></h3>
						<span v-for="(league, i) in structure.subLeagues" :key="i" :value="league">
							<h2 v-if="league.league_id && league.league_id != 0" @click="pushLink(league)" class="table_home_headline gray mb_1 cursor"><font-awesome-icon class="icon-1x" icon="flag" />{{ league.name }}</h2>
							<h2 v-else class="table_home_headline gray mb_1"><font-awesome-icon class="icon-1x" icon="flag" />{{ league.name }}</h2>
							<table cellspacing="0" cellpadding="0" class="table_home">
							<template v-for="(subleague, i) in league.subLeagues">
								<tr v-if="i % 2 == 0">
								<td @click="pushLink(subleague)"><a :style="league.name == '1. Bundesliga' ? 'margin-right: -7px !important;': league.name == '2. Bundesliga' ? 'margin-right: -1px !important;': ''" >{{ subleague.name }}</a></td>
								<td @click="pushLink(league.subLeagues[i+1])"><a>{{ league.subLeagues[i+1].name }}</a></td>
								</tr>
							</template>
						</table>
						</span>
						</div>
					</div>
					</div>
				</div>
			-->
		<ion-grid class="my-grid" id="bottom">
			<ion-row class="ion-margin parent-row">
				<div style="text-align:center;" data-header-ad-wrapper>
					<img v-if="!isPlatform('mobile')" width="1048" :src="footerImg">
					<img v-if="isPlatform('mobile')" width="1048" :src="footerMobileImg">
				</div>
			</ion-row>
			<ion-row class="ion-margin parent-row">
				<div style="margin: 10px;color: white;"><a style="margin: 10px;color: white;" href="https://www.clubinone.de" target="_blank">Powered by CLUB IN ONE</a></div>
			</ion-row>
		</ion-grid>

   </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonGrid, IonRow, IonCol, loadingController, IonSearchbar, IonItem, IonListHeader, IonLabel, IonList, IonCard,isPlatform, IonButton, IonIcon, modalController } from '@ionic/vue';
import { defineComponent, computed, ref, watch } from 'vue';
import { useQuery } from '@vue/apollo-composable'
import getIndexPageQueryFile from '../graphql/getIndexPage.query.gql'
import getLeaguesQueryFile from '../graphql/getLeagues.query.gql'
import { searchCircle } from 'ionicons/icons';
import VueScriptComponent from 'vue-script-component'
import ReviveAd from './Revive.vue'
import getDGLTeamScoreQueryFile from '../graphql/getDGLTeamScore.query.gql'
import getDGLNavigationQueryFile from '../graphql/getDGLNavigation.query.gql'
import getDGLSingleScoreQueryFile from '../graphql/getDGLSingleScore.query.gql'
import { refreshOutline, openOutline } from 'ionicons/icons';
import ShowLiveTableDialog from './ShowLiveTableDialog'
import MarqueeText from 'vue-marquee-text-component'
import { useRoute } from 'vue-router';
import Storage from "../plugins/storage.js";

export default defineComponent({
  name: 'Home',
  components: {
    VueScriptComponent, IonPage, IonContent, IonGrid, IonRow, IonCol, ReviveAd, IonSearchbar, IonItem, IonListHeader, IonLabel, IonList, IonCard, IonButton, IonIcon,MarqueeText
  },
  data() {
    return {
    }
  },
  setup() {
    const route = useRoute()
    let isLoaded = ref(false)
    let loadingSpinner = ref(null)
    let searchText = ref('')
    let searchTextTeam = ref('')
    let searchType = ref(0) // 0 = player, 1 = team
    let filterSelected = ref(true)
	const tableCategory = ref('2')
	const searchGender = ref('F')
	const searchLiga = ref({ id: 0, name: '1. Bundesliga' })
	const searchStaffel = ref({ id: 0, name: 'Nord' })
	const searchAufstieg = ref('0')
	const tableCategorys = ref(['Spieltage', 'Final Four', 'Aufstiegsspiele'])
	const searchClicked = ref(false)
	const leagueID = ref(route.params.lid != undefined ? parseInt(route.params.lid) : 0)
	const parentID = ref(route.params.pid != undefined ? parseInt(route.params.pid) : 0)
	const ltID = ref(route.params.ltid != undefined ? parseInt(route.params.ltid) : 0)
	const lid = ref(route.params.lid != undefined ? parseInt(route.params.lid) : 0)
	const pid = ref(route.params.pid != undefined ? parseInt(route.params.pid) : 0)
	const selectedViewType = ref(0)
    let selectedMatchDay = ref(0)
	const selectedRefresh = ref(0)
    const content = ref();
	const forceReload = ref(false)
	const forceSingleReload = ref(false)
    let selectedSingleRound = ref(0)
	const leagueResult = ref([])
	const teamScoresSelected = ref(undefined)
	const searchFound = ref(true)
	const hasParameter = ref(route.params.lid != undefined && route.params.pid != undefined)
	let logoTeam1 = ref(undefined)
	let logoTeam2 = ref(undefined)

    const { result: findgetIndexPageResult, loading: findgetIndexPageLoading } = useQuery(getIndexPageQueryFile, () => ({ }), () => ({ fetchPolicy: 'no-cache' }) )
    const { result: findLeaguesResult, loading: findLeaguesResultLoading } = useQuery(getLeaguesQueryFile, () => ({ searchText: searchType.value == 0 ? searchText.value : searchTextTeam.value, searchType: searchType.value }), () => ({ fetchPolicy: 'no-cache', enabled: searchClicked }) )


    let leaguesFound = computed(() => findLeaguesResult.value?.getLeagues.leagues ?? [])
    const structureFound = computed(() => findgetIndexPageResult.value?.getIndexPage.leagueStructure ?? [])
    const adLinkTop = computed(() => findgetIndexPageResult.value?.getIndexPage.adLinkTop ?? '')
    const footerImg = computed(() => findgetIndexPageResult.value?.getIndexPage.footerImg ?? '')
    const footerMobileImg = computed(() => findgetIndexPageResult.value?.getIndexPage.footerMobileImg ?? '')

	const { result: getDGLNavigationResult, refetch: getDGLNavigationRefetch } = useQuery(getDGLNavigationQueryFile, () => ({ league_id: leagueID.value, parent_id: parentID.value, league_tournament_id: ltID.value }), () => ({ fetchPolicy: 'no-cache', enabled: leagueID.value > 0 && parentID.value > 0 } ))
    const matchDays = computed(() => getDGLNavigationResult.value?.getDGLNavigation.matchDays ?? '')
    const scoringMode = computed(() => getDGLNavigationResult.value?.getDGLNavigation.scoringMode ?? '')
    const golfdeLink = computed(() => getDGLNavigationResult.value?.getDGLNavigation.golfdeLink ?? '')

    const { result: getDGLTeamScoreResult, refetch: getDGLTeamScoreRefetch, loading: getDGLTeamScoreLoading } = useQuery(getDGLTeamScoreQueryFile, () => ({ matchDayId: parseFloat(selectedMatchDay.value), viewType: selectedViewType.value }), () => ({ fetchPolicy: 'no-cache', pollInterval: 60000*5,  enabled: (selectedMatchDay.value != 0 && selectedViewType.value == 0) || forceReload.value } ))
    const teamScores = computed(() => getDGLTeamScoreResult.value?.getDGLTeamScore.teams ?? [])
    const blitzScores = computed(() => getDGLTeamScoreResult.value?.getDGLTeamScore.datablitz ?? [])
    const allFinished = computed(() => getDGLTeamScoreResult.value?.getDGLTeamScore.allFinished ?? true)
    const tickerTeam = computed(() => getDGLTeamScoreResult.value?.getDGLTeamScore.currentTicker ?? '')
    const teeinfo = computed(() => getDGLTeamScoreResult.value?.getDGLTeamScore.teeinfo ?? '')
    const leagueinfo = computed(() => getDGLTeamScoreResult.value?.getDGLTeamScore.leagueinfo ?? '')
    const gender = computed(() => getDGLTeamScoreResult.value?.getDGLTeamScore.gender ?? '')

	const { result: getDGLSingleScoreResult, refetch: getDGLSingleScoreRefetch, loading: getDGLSingleScoreLoading } = useQuery(getDGLSingleScoreQueryFile, () => ({ matchDayId: parseFloat(selectedMatchDay.value), viewType: selectedViewType.value, roundId: parseInt(selectedSingleRound.value) }), () => ({ fetchPolicy: 'no-cache', pollInterval: 60000*5, enabled: (selectedMatchDay.value != 0 && selectedViewType.value == 1 && tableCategory.value == 0) || forceSingleReload.value } ))
    const singleScoresPlayers = computed(() => getDGLSingleScoreResult.value?.getDGLSingleScore.players ?? [])
    const singleScoresRounds = computed(() => getDGLSingleScoreResult.value?.getDGLSingleScore.rounds ?? [])
    const tickerSingle = computed(() => getDGLSingleScoreResult.value?.getDGLSingleScore.currentTicker ?? '')

	const currentTicker = computed({
        get() {
          if (tickerTeam.value && tickerTeam.value != '') return tickerTeam.value
          if (tickerSingle.value && tickerSingle.value != '') return tickerSingle.value
          return ''
        },
      })

	watch(singleScoresRounds, (newVal, oldVal) => {
  	  forceSingleReload.value = false
      if (newVal && newVal.length > 0 && (selectedSingleRound.value == 0 || !newVal.find(f => f.id == selectedSingleRound.value)))
        selectedSingleRound.value =  newVal[0].id
    })

	watch(searchGender, (newValue, prevValue) => {
		searchLiga.value = { id: 0, name: structureFound.value.find(f => f.header == (searchGender.value == 'F' ? 'Damen' : 'Herren'))?.subLeagues[0].name}
    })

	watch(searchLiga, (newValue, prevValue) => {
		searchStaffel.value = { id: 0, name: structureFound.value.find(f => f.header == (searchGender.value == 'F' ? 'Damen' : 'Herren'))?.subLeagues?.find(f => f.name == searchLiga.value.name).subLeagues[0].name}
    })

    watch(tableCategory, async (newVal, oldVal) => {
		if ((leagueID.value > 0 && parentID.value > 0))
			return
		forceReload.value = true 
		leagueID.value = 0
		parentID.value = 0
		leagueID.value = (await Storage.getItem('leagueID')) != null ? parseInt(await Storage.getItem('leagueID')) : 0;
		parentID.value =  (await Storage.getItem('parentID')) != null ? parseInt(await Storage.getItem('parentID')) : 0;
		tableCategory.value =  (await Storage.getItem('tableCategory')) != null && tableCategorys.value.length - 1 >= (await Storage.getItem('tableCategory')) ? (await Storage.getItem('tableCategory')) : tableCategory.value;
		searchGender.value =  (await Storage.getItem('searchGender')) != null ? (await Storage.getItem('searchGender')) : 'F';
		searchLiga.value =  (await Storage.getItem('searchLiga')) != null ? JSON.parse(await Storage.getItem('searchLiga')) : { id: 0, name: '1. Bundesliga' };
		searchStaffel.value =  (await Storage.getItem('searchStaffel')) != null ? JSON.parse(await Storage.getItem('searchStaffel')) : { id: 0, name: 'Nord' };
		selectedMatchDay.value = 0
		selectedViewType.value = 0
    }, { immediate: true })

    watch(matchDays, (newVal, oldVal) => {
      selectedMatchDay.value =  newVal.find(f => f.selected == true)?.id
    })

    watch(teamScores, (newVal, oldVal) => {
		if (newVal && newVal.length <= 0) {
			teamScoresSelected.value = newVal
			return
		}
	  if (newVal && newVal.length == 2) {
	 	logoTeam1.value = newVal[0].name == "Hamburger GC" ? require('@/assets/images/logoHamburg.jpeg/') : undefined
		logoTeam2.value = newVal[1].name == "Hubbelrath, GC" ? require('@/assets/images/logoHubbelrath.png/') : undefined
		logoTeam1.value = logoTeam1.value == undefined && newVal[0].name == "Hubbelrath, GC" ? require('@/assets/images/logoHubbelrath.png/') : logoTeam1.value
		logoTeam2.value = logoTeam2.value == undefined && newVal[1].name == "Hamburger GC" ? require('@/assets/images/logoHamburg.jpeg/') : logoTeam2.value
	  }
	  searchFound.value = true
	  const teamScoresTemp = teamScoresSelected.value
	  teamScoresSelected.value = newVal
	  if (teamScoresTemp)
		teamScoresTemp.forEach(item => {
			if (item.showDetails == true)
				teamScoresSelected.value.find(f => f.name == item.name).showDetails = true ?? false

			let roundNum = 0
			item.rounds.forEach(round => {
				let playerNum = 0
				round.players.forEach(player => {
					if (player.showScorecard == true)
						teamScoresSelected.value.find(f => f.name == item.name).rounds[roundNum].players[playerNum].showScorecard = true
					playerNum++
				})
				roundNum++
			})
	  })
	  
	  forceReload.value = false

	  if (scoringMode.value == 3)
	  	setTimeout(() => { var currentSlot = document.getElementById('ankerMatchplay'); if (currentSlot) currentSlot.scrollIntoView({behavior: 'smooth'}); }, 500)
	  else
 	    setTimeout(() => { var currentSlot = document.getElementById('ankerMatchplay'); if (currentSlot) currentSlot.scrollIntoView({behavior: 'smooth'}); }, 500)

    })

	watch(leaguesFound, (newValue, prevValue) => {
		leagueResult.value = newValue
		if (newValue.length == 0)
			searchFound.value = false
		else
			searchFound.value = true

		searchClicked.value = false
    })

	watch([getDGLTeamScoreLoading, findLeaguesResultLoading, getDGLSingleScoreLoading, findgetIndexPageLoading], ([newLoading, newLoading1, newLoading2, newLoading3], [prevLoading, prevLoading1, prevLoading2, prevLoading3])=> {
      if ((!newLoading && !newLoading1 && !newLoading2 && !newLoading3) && loadingSpinner.value != null) {
          isLoaded.value = true
          loadingSpinner.value.dismiss()
          loadingSpinner.value = null
		  forceReload.value = false
		  forceSingleReload.value = false
          return
      }
      else if ((newLoading || newLoading1 || newLoading2 || newLoading3) && loadingSpinner.value == null) {
        loadingController.create().then(res => {
			  if (loadingSpinner.value != null)
				  loadingSpinner.value.dismiss()
              loadingSpinner.value = res
              res.present().then(res1 => { 
                if (!getDGLTeamScoreLoading.value && !findLeaguesResultLoading.value && !getDGLSingleScoreLoading.value && !findgetIndexPageLoading.value && loadingSpinner.value != null) {
                  loadingSpinner.value.dismiss() 
                  loadingSpinner.value = null
				  forceReload.value = false
				  forceSingleReload.value = false
                }
              })
            }).catch(err => { })
      }
    })

    return {
      structureFound, adLinkTop, findgetIndexPageLoading, footerImg, footerMobileImg, searchText, leaguesFound, searchTextTeam, searchType, searchCircle, filterSelected, tableCategory, searchGender, searchLiga, searchStaffel, tableCategorys, searchClicked, leagueID, parentID, selectedViewType,  teamScores, matchDays, selectedMatchDay, isPlatform, content, refreshOutline, openOutline, getDGLTeamScoreRefetch, isLoaded, teeinfo, searchAufstieg, getDGLTeamScoreLoading, getDGLSingleScoreRefetch, singleScoresRounds, selectedSingleRound, leagueResult, singleScoresPlayers, forceSingleReload, blitzScores, allFinished, scoringMode, golfdeLink, tickerTeam, tickerSingle, currentTicker, hasParameter, gender, leagueinfo, teamScoresSelected, searchFound, lid, pid, logoTeam1, logoTeam2
    }
  },
  methods: {
	getPercent(teampos, projection) {
		let pointsWithoutScore = 0
		let pointsWithScore = 0
		let totalMatches = 0
		this.teamScoresSelected[0].rounds.forEach(round => {
			totalMatches = round.numMatchesFF
			
			round.players.forEach(player => {
				pointsWithoutScore += (player.holesPlayed == 0 ? 0 : parseFloat(teampos == 0 ? player.score : player.scoreOpponent))
				pointsWithScore += (player.holesPlayed == 0  || player.isCompleted != '(final)' ? 0 : parseFloat(teampos == 0 ? player.score : player.scoreOpponent))
			})
		});

		if (projection)
			return (100 / totalMatches * pointsWithoutScore) + '%'
		else
			return (100 / totalMatches * pointsWithScore) + '%'
	},
	openInNewTab(url) {
      window.open(url, '_blank', 'noreferrer');
    },
    async openLiveTable() {
      const modal = await modalController
        .create({
          component: ShowLiveTableDialog,
          cssClass: this.isPlatform('mobile') ? 'tournamentLists-modal-mobile' : 'tournamentLists-modal',
          componentProps: {
            propsData: {
              teamScores: this.blitzScores,
              allFinished: this.allFinished,
              leagueInfo: this.leagueinfo + ' - ' + this.matchDays.find(f => f.id == this.selectedMatchDay)?.name?.split('-')[0]?.trim()
            }
          },
        })
      return modal.present();

    },
    openLink() {
		if (this.leagueinfo.includes('Final Four') || this.leagueinfo.includes('Aufstieg'))
		    window.open(window.location.href + this.leagueID+ '/' + this.parentID + '/' + parseFloat(this.selectedMatchDay), '_blank');
		else
		window.open(window.location.href + this.leagueID + '/' + this.parentID, '_blank');
		//this.$router.push('/' + this.leagueID+ '/' + this.parentID)
    },
    refreshScore() {
      this.isLoaded = false
      if (this.selectedViewType == 0)
        this.getDGLTeamScoreRefetch()
      else
        this.getDGLSingleScoreRefetch()
    },
	getSign(score) {
      return score == 0 ? '' : score > 0 ? '+' : ''
    },
	getScoreClass(par, score) {
      if (score - par == 0)
        return 'par'
      if (score - par == -1)
        return 'birdie'
      if (score > 0 && score - par <= -2)
        return 'eagle'
      if (score - par == 1)
        return 'bogey'
      if (score - par == 2)
        return 'doublebogey'
      if (score - par > 2)
        return 'doublebogey'
    },
	getPlusSign(score) {
      if (score == 0)
        return 'Par'
      return score > 0 ? '+' + score : score
    },
    filterStrcuture(name, level) {
		//if (!this.searchClicked)
		//	return false

		if (level == 0 && ((this.tableCategory == '0' && (name == 'FINAL FOUR' || name == 'Aufstieg')) || (this.tableCategory == '1' && (name == 'Damen' || name == 'Herren'))))
			return false
		
		if (level == 0 && this.searchGender == 'F' && name == 'Herren') {
			return false
		}
		if (level == 0 && this.searchGender == 'M' && name == 'Damen') {
			return false
		}
		if (level == 1 && this.searchLiga == '1' && name != '1. Bundesliga') {
			return false
		}
		if (level == 1 && this.searchLiga == '2' && name != '2. Bundesliga') {
			return false
		}
		if (level == 1 && this.searchLiga == '3' && name != 'Regionalliga') {
			return false
		}
		if (level == 1 && this.searchLiga == '4' && name != 'Oberliga') {
			return false
		}
		if (level == 1 && this.searchLiga == '5' && name != 'Landesliga') {
			return false
		}
		if (level == 2 && this.searchStaffel == 'N' && !name.includes('Nord')) {
			return false
		}
		if (level == 2 && this.searchStaffel == 'S' && !name.includes('Süd')) {
			return false
		}
		if (level == 2 && this.searchStaffel == 'W' && !name.includes('West')) {
			return false
		}
		if (level == 2 && this.searchStaffel == 'O' && !name.includes('Mitte')) {
			return false
		}
		return true
    },
    async findPlayer(searchTerm) {
	  this.searchClicked = true
      this.searchType = 0
      this.searchTextTeam = ''
    },
    async findLiga() {
		/*if (this.leagueID > 0) {
			this.refreshScore()
			return
		}*/
		if (this.tableCategory == 0) {
			const league = this.structureFound.find(f => f.header == (this.searchGender == 'F' ? 'Damen' : 'Herren'))
			if (league) {
				const subleague = league.subLeagues.find(f => f.name == this.searchLiga.name)
				if (subleague) {
					const subleague1 = subleague.subLeagues.find(f => f.name.includes(this.searchStaffel.name))
					if (subleague1) {
						if (this.leagueID == subleague1.league_id) {
							this.refreshScore()
						}
						else {
							this.isLoaded = false
							this.leagueID = subleague1.league_id
							this.parentID = subleague1.parent_id
							await Storage.setItem('leagueID', subleague1.league_id);
							await Storage.setItem('parentID', subleague1.parent_id);
							await Storage.setItem('tableCategory', this.tableCategory);
							await Storage.setItem('searchGender', this.searchGender);
							await Storage.setItem('searchLiga', JSON.stringify(this.searchLiga));
							await Storage.setItem('searchStaffel', JSON.stringify(this.searchStaffel));
						}
					}
				}
			}
		}
		else if (this.tableCategory == 1) {
			const league = this.structureFound.find(f => f.header == 'FINAL FOUR')?.subLeagues.find(f => f.name == (this.searchGender == 'F' ? 'Damen' : 'Herren'))
			if (league) {
				this.isLoaded = false
				this.leagueID = league.league_id
				this.parentID = league.parent_id
				await Storage.setItem('leagueID', league.league_id);
				await Storage.setItem('parentID', league.parent_id);
				await Storage.setItem('tableCategory', this.tableCategory);
				await Storage.setItem('searchGender', this.searchGender);
				await Storage.setItem('searchLiga', JSON.stringify(this.searchLiga));
				await Storage.setItem('searchStaffel', JSON.stringify(this.searchStaffel));
			}
		}
		else if (this.tableCategory == 2) {
			const league = this.structureFound.find(f => f.header == 'Aufstieg')?.subLeagues[this.searchAufstieg]
			if (league) {
				this.isLoaded = false
				this.leagueID = league.league_id
				this.parentID = league.parent_id
				await Storage.setItem('leagueID', league.league_id);
				await Storage.setItem('parentID', league.parent_id);
				await Storage.setItem('tableCategory', this.tableCategory);
				await Storage.setItem('searchGender', this.searchGender);
				await Storage.setItem('searchLiga', JSON.stringify(this.searchLiga));
				await Storage.setItem('searchStaffel', JSON.stringify(this.searchStaffel));
			}
		}
    },
    async findTeam(searchTerm) {
	  this.searchClicked = true
      this.searchType = 1
      this.searchText = ''
    },
    async pushLink(league, search = false) {
	  if (this.tableCategory == 0 && !search && league != null)
		return
 	  this.searchText = ''
	  this.searchTextTeam = ''
	  if (league != null) {
		this.leagueID = league.league_id
  	    this.parentID = league.parent_id
 	    await Storage.setItem('leagueID', league.league_id);
		await Storage.setItem('parentID', league.parent_id);
		await Storage.setItem('tableCategory', this.tableCategory);
		await Storage.setItem('searchGender', this.searchGender);
		await Storage.setItem('searchLiga', JSON.stringify(this.searchLiga));
		await Storage.setItem('searchStaffel', JSON.stringify(this.searchStaffel));
  	  }
	  this.leagueResult = []
      //this.$router.push('/score/' + league.league_id + '/' + league.parent_id)
    },
    toggleDisplay(league) {
      if (!league.displayValue)
        league.displayValue = 'none'
      league.displayValue = league.displayValue === 'none' ? 'block' : 'none';
    }
  }
});

if (document.location.search.match(/type=embed/gi)) {
  window.parent.postMessage("resize", "*");
}

</script>

<style scoped>

.search-input {
	--border-radius: 20px !important;
	--box-shadow: 0px 0px 0px 2px #f1f1f1;
	--icon-color: #004587;
}

.my-grid {
	background-color: #004587;
}
.parent-row {
	background-color: #004587;
    align-items: center !important;
    justify-content: center !important;
 }
.center-row {
    align-items: center !important;
    justify-content: center !important;
 }

.cursor {
    cursor: pointer;
}


.icon-1x {
    font-size: 0.8em;
    margin-right: 5px;
}

.scroll-right {
 height: 50px;	
 overflow: hidden;
 position: relative;
 background: yellow;
 color: orange;
 border: 1px solid orange;
}
.scroll-right p {
 position: absolute;
 width: 100%;
 height: 100%;
 margin: 0;
 line-height: 50px;
 text-align: center;
 /* Starting position */
 -moz-transform:translateX(-100%);
 -webkit-transform:translateX(-100%);	
 transform:translateX(-100%);
 /* Apply animation to this element */	
 -moz-animation: scroll-right 15s linear infinite;
 -webkit-animation: scroll-right 15s linear infinite;
 animation: scroll-right 15s linear infinite;
}
/* Move it (define the animation) */
@-moz-keyframes scroll-right {
 0%   { -moz-transform: translateX(-100%); }
 100% { -moz-transform: translateX(100%); }
}
@-webkit-keyframes scroll-right {
 0%   { -webkit-transform: translateX(-100%); }
 100% { -webkit-transform: translateX(100%); }
}
@keyframes scroll-right {
 0%   { 
 -moz-transform: translateX(-100%); /* Browser bug fix */
 -webkit-transform: translateX(-100%); /* Browser bug fix */
 transform: translateX(-100%); 		
 }
 100% { 
 -moz-transform: translateX(100%); /* Browser bug fix */
 -webkit-transform: translateX(100%); /* Browser bug fix */
 transform: translateX(100%); 
 }
}

</style>