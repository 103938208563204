import i18next from 'i18next';

const en = require('../i18nData/en');
const de = require('../i18nData/de');
import translate from './translate'

export default {
    install: (app) => {
      app.config.globalProperties.$t = key => {
        return translate(key)
      },
      app.config.globalProperties.$changeLanguage = key => {
        return i18next.changeLanguage(key)
      },
      app.config.globalProperties.$getLanguage = key => {
        return i18next.language
      }
    }
  }