module.exports = {
  translation: {
    "total": "Gesamt",
    "round": "Runde",
    "country": "Land",
    "noScoresFound": "Keine Scores für dieses Turnier gefunden.",
    "position": "Position",
    "thru": "Nach",
    "eagle": "Eagle oder besser",
    "birdie": "Birdie",
    "par": "Par",
    "bogey": "Bogey",
    "doublebogey": "Double Bogey",
    "triplebogey": "Triple Bogey oder schlechter ",
    
    // Errors
 }
}