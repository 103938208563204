import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue'
import ScoreDGL from '../views/ScoreDGL.vue'
import ScoreDGV from '../views/ScoreDGV.vue'
import ScoreDMM from '../views/ScoreDMM.vue'
import Wartung from '../views/Wartung.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/wartung',
    name: 'Wartung',
    component: Wartung
  },
  {
    path: '/score/:lid/:pid',
    name: 'ScoreDGL',
    component: ScoreDGL
  },
  {
    path: '/score/:tid',
    name: 'ScoreDGV',
    component: ScoreDGV
  },
  {
    path: '/:lid/:pid',
    name: 'Home2',
    component: Home
  },
  {
    path: '/:lid/:pid/:ltid',
    name: 'Home3',
    component: Home
  },
  {
    path: '/dmm/:lid/:groupid/:roundid',
    name: 'ScoreDMM',
    component: ScoreDMM
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name == 'Wartung' && (process.env.VUE_APP_IS_WARTUNG == undefined || process.env.VUE_APP_IS_WARTUNG == 'false'))
    router.push('/')
  else if (to.name == 'Wartung' && process.env.VUE_APP_IS_WARTUNG == 'true')
    next()
  else if (process.env.VUE_APP_IS_WARTUNG == 'true' && document.URL.startsWith('https://dgvpublic.cio.golf'))
    router.push('wartung')
  else
    next()
})

export default router
