<template>
    <div>
      <ins :data-revive-zoneid="zoneId" :data-revive-id="reviveId" data-revive-target="_blank"></ins>
      <component is="script" src="//test-adserver.clubinone.de/www/delivery/asyncjs.php" async></component>
    </div>
    </template>
     
    <script>
     
    export default {
      name: 'ReviveAd',
      props: {
        zoneId: Number,
        reviveId: String
      },
      mounted() {
        this.reviveRefresh()
      },
      methods: {
        reviveRefresh() {
          if (window.reviveAsync) window.reviveAsync[this.reviveId].apply(reviveAsync[this.reviveId].detect())
        }
      }
    }
    </script>
    