<template>
  <ion-page>
    <ion-content style="background-color: #004587;">
      <!-- <ion-grid class="my-grid">
        <ion-row class="ion-margin parent-row">
          <ion-card class="ion-no-padding">
            <div style="text-align:center;" data-header-ad-wrapper>
              <VueScriptComponent v-if="adLinkTop && adLinkTop !=''" :script="adLinkTop" />
            </div>
          </ion-card>
        </ion-row>
      </ion-grid>-->
        <ion-grid class="my-grid">
          <ion-row class="ion-justify-content-center ion-margin-start ion-margin-end parent-row">
            <ion-card>
              <ion-grid>
                <ion-row class="ion-no-margin">
                  <ion-col size="auto">
                    <a href="/"><ion-button fill="none" class="ion-no-padding ion-no-margin" title="Zurück zur Übersicht"><ion-icon :icon="chevronBackOutline" style="zoom:1.0;" title=""></ion-icon></ion-button></a>
                    <p class="" style="margin: -10px;"></p>                    
                    <ion-button fill="none" class="ion-no-padding  ion-no-margin" title="Aktualisieren" @click="refreshScore()"><ion-icon :icon="refreshOutline" style="zoom:1.0;"></ion-icon></ion-button>
                  </ion-col>
                  <ion-col size="auto">
                    <img style="width: 150px; padding: 0 0 5px 5px;" class="dgv-header-logo tw-h-auto" :src="logoImg">
                  </ion-col>
                  <ion-col size="auto" style="font-size: medium;" class="ion-padding-top">
                      <ion-select interface="popover" v-model="selectedNav">
                        <ion-select-option v-for="(nav, index) in leaguesNav" :key="index" :value="nav.league_id">{{ nav.name }}</ion-select-option>          
                      </ion-select>
                  </ion-col>
                  <ion-col size="auto" style="font-size: medium;" class="ion-padding-top">
                      <ion-select interface="popover" v-model="selectedMatchDay">
                        <ion-select-option v-for="(day, index) in matchDays" :key="index" :value="day.id">{{ day.name }}</ion-select-option>          
                      </ion-select>
                  </ion-col>
                  <ion-col size="auto" style="font-size: medium;" class="ion-padding-top">
                      <ion-select v-if="scoringMode != 3" interface="popover" v-model="selectedViewType" fill="outline" mode="md" style="--border-width: 2px;">
                        <ion-select-option v-for="vt in viewTypes" :key="vt.type_id" :value="vt.type_id">{{ vt.name + '-Anzeige' }}</ion-select-option>          
                      </ion-select>
                  </ion-col>
                  <ion-col size="auto" style="font-size: medium;" class="ion-padding-top">
                      <ion-select interface="popover" v-if="selectedViewType == 1" v-model="selectedSingleRound">
                        <ion-select-option v-for="(round, index) in singleScoresRounds" :key="index" :value="round.id">{{ 'Runde ' + round.roundIndex }}</ion-select-option>          
                      </ion-select>
                  </ion-col>
                </ion-row>
                <ion-row v-if="scoringMode != 3 && teamScores && teamScores.length > 0 && (!allFinished || teamScores[0].rounds.length == 3)">
                  <table class="ion-no-padding ion-no-margin">
                    <tr>
                      <td style="font-size: 20px;">
                        <ion-icon :icon="videocamOutline" color="success" style="font-size: 20px; padding-top: 5px"></ion-icon>
                      </td>
                      <td style="font-size: medium;">
                        <div style="margin-left: 10px;cursor: pointer;" @click="openLiveTable()">Zur Blitztabelle</div>
                      </td>
                      <td>
                        <ion-icon style="font-size: 18px; margin-left: 5px;cursor: pointer;padding-bottom: 2px;" @click="openLiveTable()" :ios="openOutline" :md="openOutline" name="closeOutline" class="ion-no-padding ion-float-right"></ion-icon>
                      </td>
                    </tr>
                  </table>
                </ion-row>
                <ion-row v-if="scoringMode != 3 && teamScores.length > 0 && (allFinished && teamScores[0].rounds.length != 3)">
                  <table class="ion-no-padding ion-no-margin">
                    <tr>
                      <td>
                        <ion-icon style="font-size: 18px; margin-left: 5px;margin-right: 20px; cursor: pointer;padding-bottom: 2px;" :icon="showDetails ? chevronUpOutline : chevronDownOutline" class="ion-no-padding ion-float-right" @click="openAll()"></ion-icon>
                      </td>
                      <td style="font-size: 20px;" v-if="golfdeLink && golfdeLink != ''">
                        <ion-icon :icon="videocamOffOutline" color="danger" style="font-size: 20px; padding-top: 5px"></ion-icon>
                      </td>
                      <td style="font-size: medium;cursor: pointer;" v-if="golfdeLink && golfdeLink != ''">
                        <div style="margin-left: 10px;" @click="openInNewTab(golfdeLink)">Zur Ligatabelle</div>
                      </td>
                      <td v-if="golfdeLink && golfdeLink != ''">
                        <ion-icon style="font-size: 18px; margin-left: 5px;cursor: pointer;padding-bottom: 2px;" :icon="openOutline" class="ion-no-padding ion-float-right" @click="openInNewTab(golfdeLink)"></ion-icon>
                      </td>
                    </tr>
                  </table>
                </ion-row>
              </ion-grid>
            </ion-card>
            <!-- <ion-col>
                <div class="cf wrap noTop">
                <div class="controls cf">
                  <div class="refresh">
                    Aktualisierung alle <select name="refresh" class="refresh" v-model="selectedRefresh">
                      <option v-for="refresh in refreshSelect" :key="refresh.id" :value="refresh.id">{{ refresh.name }}</option>
                      </select>
                      <a @click="refreshScore()" class="refresh"><img src="~@/assets/images/refresh.gif"></a>
                  </div>
                  <div class="filter">
                    <div><a href="/">Zurück</a></div>
                    <div style="width: 800px;">
                      <table>
                      <tr>
                        <td style="vertical-align: center;">
                        <div><img style="width: 150px; padding: 0 0 5px 5px;" class="dgv-header-logo tw-h-auto" :src="logoImg"></div>
                      </td>
                      <td>
                      <form method="GET" action="">
                      <select name="lId" title="Liga" class="query" v-model="selectedNav">
                        <option v-for="nav in leaguesNav" :key="nav.league_id" :value="nav.league_id">{{ nav.name }}</option>
                      </select>
                      <select name="tId" title="Spieltag" class="query"  v-model="selectedMatchDay"> 
                        <option v-for="day in matchDays" :key="day.id" :value="day.id">{{ day.name }}</option>
                      </select>
                      <select v-if="scoringMode != 3" name="viewMode" title="Ansicht" class="query" v-model="selectedViewType">
                        <option v-for="vType in viewType" :key="vType.id" :value="vType.id">{{ vType.name }}</option>
                      </select>
                      <select v-if="selectedViewType == 1" name="rId" title="Runde" class="query" v-model="selectedSingleRound">
                        <option  v-for="round in singleScoresRounds" :key="round.id" :value="round.id">Runde {{ round.roundIndex }} - {{ round.playForm }}</option>
                      </select>
                      </form>
                      </td>
                      </tr>
                      <tr v-if="scoringMode != 3 && teamScores && teamScores.length > 0 && !allFinished">
                        <td colspan="3"><div class="roundInfo ion-no-padding ion-no-margin">
                          <table class="ion-no-padding ion-no-margin">
                            <tr>
                              <td style="font-size: 20px;">
                                <ion-icon :icon="videocamOutline" color="success" style="font-size: 20px; padding-top: 5px"></ion-icon>
                              </td>
                              <td style="font-size: medium;">
                                <div style="margin-left: 10px;cursor: pointer;" @click="openLiveTable()">Zur Blitztabelle</div>
                              </td>
                              <td>
                                <ion-icon style="font-size: 18px; margin-left: 5px;cursor: pointer;padding-bottom: 2px;" @click="openLiveTable()" :ios="openOutline" :md="openOutline" name="closeOutline" class="ion-no-padding ion-float-right"></ion-icon>
                              </td>
                            </tr>
                          </table>
                        </div></td>
                      </tr>
                      <tr v-if="scoringMode != 3 && teamScores.length > 0 && allFinished">
                        <td colspan="3"><div class="roundInfo ion-no-padding ion-no-margin">
                          <table class="ion-no-padding ion-no-margin">
                            <tr>
                              <td style="font-size: 20px;">
                                <ion-icon :icon="videocamOffOutline" color="danger" style="font-size: 20px; padding-top: 5px"></ion-icon>
                              </td>
                              <td style="font-size: medium;cursor: pointer;">
                                <div style="margin-left: 10px;">Zur Ligatabelle</div>
                              </td>
                              <td>
                                <ion-icon style="font-size: 18px; margin-left: 5px;cursor: pointer;padding-bottom: 2px;" :icon="openOutline" class="ion-no-padding ion-float-right"></ion-icon>
                              </td>
                            </tr>
                          </table>
                        </div></td>
                      </tr>
                      </table>    
                    </div>                
                  </div>
                </div>
              </div>
            </ion-col>-->
          </ion-row>

          <ion-row  v-if="currentTicker && currentTicker.tickerText != ''">
            <ion-col>
              <marquee-text class="ticker-text" :key="currentTicker.id" :duration="8" :repeat="15">
                {{ currentTicker.tickerText }}&nbsp; &nbsp; &nbsp; 
              </marquee-text>
            </ion-col>
          </ion-row>
        </ion-grid>

        <ion-grid class="my-grid" v-if="teamScores.length == 0 && singleScoresRounds.length == 0 && !getDGLTeamScoreLoading && !getDGLSingleScoreLoading">
          <ion-row>
            <ion-col>
              <div class="head">
                <p>Keine Scores für diesen Spieltag gefunden.</p>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>

          <ion-grid class="my-grid" v-if="scoringMode != 3 && teamScores.length > 0 && selectedViewType == 0">
            <template v-for="(team, index) in teamScores" :key="index">
              <ion-row v-if="index == 0 && showBanner1 == true && team.showDetails == true" class="ion-margin">
                <ion-card class="ion-no-padding">
                  <div style="text-align:left;position: relative;" data-header-ad-wrapper>
                      <VueScriptComponent script='<script type="text/javascript" src="https://ad1.adfarm1.adition.com/js?wp_id=4804266&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_39}"></script>' />
                  </div>
                </ion-card>
              </ion-row>
              <ion-row v-else-if="index == 1 && showBanner2 == true && team.showDetails == true" class="ion-margin">
                <ion-card class="ion-no-padding">
                  <div style="text-align:left;position: relative;" data-header-ad-wrapper>
                    <VueScriptComponent script='<script type="text/javascript" src="https://ad1.adfarm1.adition.com/js?wp_id=4804267&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_39}"></script>' />
                  </div>
                </ion-card>
              </ion-row>
              <ion-row v-else-if="index == 2 && showBanner3 == true && team.showDetails == true" class="ion-margin">
                <ion-card class="ion-no-padding">
                  <div style="text-align:left;position: relative;" data-header-ad-wrapper>
                    <VueScriptComponent script='<script type="text/javascript" src="https://ad1.adfarm1.adition.com/js?wp_id=4804268&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_39}"></script>' />
                  </div>
                </ion-card>
              </ion-row>
              <ion-row v-else-if="index == 3 && showBanner4 == true && team.showDetails == true" class="ion-margin">
                <ion-card class="ion-no-padding">
                  <div style="text-align:left;position: relative;" data-header-ad-wrapper>
                    <VueScriptComponent script='<script type="text/javascript" src="https://ad1.adfarm1.adition.com/js?wp_id=4804269&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_39}"></script>' />
                  </div>
                </ion-card>
              </ion-row>
              <ion-row v-else-if="index == 4 && showBanner5 == true && team.showDetails == true" class="ion-margin">
                <ion-card class="ion-no-padding">
                  <div style="text-align:left;position: relative;" data-header-ad-wrapper>
                    <VueScriptComponent script='<script type="text/javascript" src="https://ad1.adfarm1.adition.com/js?wp_id=4804270&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_39}"></script>' />
                  </div>
                </ion-card>
              </ion-row>

              <ion-row class="ion-margin">
                <ion-card>
                  <ion-card-header class="ion-no-margin">
                    <ion-card-title class="ion-no-padding">
                      <ion-grid>
                        <ion-row class="cursor" @click="team.showDetails == undefined ? team.showDetails = true : team.showDetails = !team.showDetails">
                          <ion-col class="teams" size="auto">{{ index + 1 }}. {{ team.name }}</ion-col>
                          <ion-col class="ion-text-right ion-align-self-end teams">{{ getPlusSign(team.totalScore) }}</ion-col>
                        </ion-row>
                      </ion-grid>
                    </ion-card-title>
                    <ion-card-content class="ion-no-margin ion-no-padding" v-if="team.showDetails">
                      <ion-grid>
                        <ion-row v-for="round in team.rounds" :key="round" :value="round">
                            <ion-grid>
                              <ion-row>
                                <div class="roundInfo">Runde {{ round.roundIndex  }} -  {{ round.playForm  }}</div>
                              </ion-row>
                              <ion-row style="font-weight: bold;border-bottom: solid;">
                                <ion-col size="2">Name</ion-col>
                                <ion-col>Mannschaft</ion-col>
                                <ion-col size="1">HCPI&#8482;</ion-col>
                                <ion-col size="1">+/-</ion-col>
                                <ion-col size="1">Nach</ion-col>
                                <ion-col size="1">Gesamt</ion-col>
                              </ion-row>
                              <ion-row @click="player.showScorecard = !player.showScorecard" class="cursor" style="border-bottom: solid;border-bottom-color: lightgray;" v-for="player in round.players" :key="player">
                                <ion-col size="2">{{ player.name }}</ion-col>
                                <ion-col>{{ player.clubName }}</ion-col>
                                <ion-col size="1">{{ player.hcp }}</ion-col>
                                <ion-col size="1">{{ player.partnerNum == 2 ? '' : player.showTeetime != '' ? player.showTeetime : player.isStreicher ? '(' + getPlusSign(player.score) + ')' : getPlusSign(player.score) }}</ion-col>
                                <ion-col size="1">{{ player.partnerNum == 2 ? '' : player.showTeetime != '' ? player.showTeetime : player.playedHoles }}</ion-col>
                                <ion-col size="1">{{ player.partnerNum == 2 ? '' : player.showTeetime != '' ? player.showTeetime : player.playedHoles == 'F' ? player.isStreicher ? '(' + player.strokes + ')' : player.strokes : '-' }}</ion-col>

                                <ion-row v-if="player.showScorecard">
                                <table class="playerScore">
                                  <thead>
                                    <tr>
                                      <th></th>
                                      <th v-for="i in 18" :key="i">{{ i }}</th>
                                      <th>+/-</th>
                                      <th>Gesamt</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>Par</td>
                                      <td class="small" v-for="par in player.pars" :key="par">{{ par }}</td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td :class="'small ' +  getScoreClass(player.pars[index], player.scores[index]) + ' ' + getColorClass(player.pars[index], player.scores[index])" v-for="(score, index) in player.scores" :key="index" :value="score">{{ score == 0 ? '-' : score }}</td>
                                      <td class="small">{{ getPlusSign(player.score) }}</td>
                                      <td>{{ player.playedHoles == 'F' ? player.strokes : '-' }}</td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                  </table>
                                  <p class="legend">
                                    <span class="square eagle"></span> - Eagle oder besser ({{ getParDiff(player.pars, player.scores, -2) }})
                                    <span class="square birdie"></span> - Birdie ({{ getParDiff(player.pars, player.scores, -1) }})
                                    <span class="square par"></span> - Par ({{ getParDiff(player.pars, player.scores, 0) }})
                                    <span class="square bogey"></span> - Bogey ({{ getParDiff(player.pars, player.scores, 1) }})
                                    <span class="square doublebogey"></span> - Double Bogey ({{ getParDiff(player.pars, player.scores, 2) }})
                                    <span class="square triplebogey"></span> - Triple Bogey oder schlechter ({{ getParDiff(player.pars, player.scores, 3) }})
                                  </p>
                              </ion-row>


                              </ion-row>

                              <ion-row style="border-top: solid;border-top-width: 1px;border-top-color: black;border-bottom: solid;border-bottom-color: black;">
                                <ion-col size="2">Gesamt</ion-col>
                                <ion-col>&nbsp;</ion-col>
                                <ion-col size="1">&nbsp;</ion-col>
                                <ion-col size="1"><strong v-if="round.players.length > 0">{{ getSign(round.score) }}{{ round.score == 0 ? 'Par' : round.score }}</strong></ion-col>
                                <ion-col size="1">&nbsp;</ion-col>
                                <ion-col size="1"><strong v-if="round.players.length > 0 && round.players.filter(f => f.playedHoles != 'F').length == 0">{{ round.strokes }}</strong></ion-col>
                              </ion-row>
                            </ion-grid>
                        </ion-row>
                      </ion-grid>
                    </ion-card-content>
                  </ion-card-header>
                </ion-card>
              </ion-row>
            </template>
          </ion-grid>

        <!-- <ion-grid v-if="scoringMode != 3 && teamScores.length > 0 && selectedViewType == 0"> 
          <ion-row>
            <ion-col>

              <div v-for="(team, index) in teamScores" :key="index" :value="team">
                <div v-if="index == 0 && showBanner1" style="text-align:left; margin-bottom: 10px;" data-header-ad-wrapper>
                   <VueScriptComponent script='<script type="text/javascript" src="https://ad1.adfarm1.adition.com/js?wp_id=4804266&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_39}"></script>' />
                </div>
                <div v-if="index == 1 && showBanner2" style="text-align:left; margin-bottom: 10px;" data-header-ad-wrapper>
                  <VueScriptComponent script='<script type="text/javascript" src="https://ad1.adfarm1.adition.com/js?wp_id=4804267&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_39}"></script>' />
                </div>
                <div v-if="index == 2 && showBanner3" style="text-align:left; margin-bottom: 10px;" data-header-ad-wrapper>
                  <VueScriptComponent script='<script type="text/javascript" src="https://ad1.adfarm1.adition.com/js?wp_id=4804268&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_39}"></script>' />
                </div>
                <div v-if="index == 3 && showBanner4" style="text-align:left; margin-bottom: 10px;" data-header-ad-wrapper>
                  <VueScriptComponent script='<script type="text/javascript" src="https://ad1.adfarm1.adition.com/js?wp_id=4804269&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_39}"></script>' />
                </div>
                <div v-if="index == 4 && showBanner5" style="text-align:left; margin-bottom: 10px;" data-header-ad-wrapper>
                  <VueScriptComponent script='<script type="text/javascript" src="https://ad1.adfarm1.adition.com/js?wp_id=4804270&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_39}"></script>' />
                </div>

                <div class="head">
                  <span class="rank">{{ index + 1 }}</span><span class="club">{{ team.name  }}</span><span class="score">{{ getPlusSign(team.totalScore)    }}</span>
                </div>

                <div  v-for="round in team.rounds" :key="round" :value="round">
                  <div class="roundInfo">Runde {{ round.roundIndex  }} -  {{ round.playForm  }}</div>

                  <table class="ranking">
                      <thead>
                      <tr>
                      <th style="width: 20%">Name</th>
                      <th>Mannschaft</th>
                      <th>HCPI&#8482;</th>
                      <th>+/-</th>
                      <th>Nach</th>
                      <th>Gesamt</th>
                      </tr>
                      </thead>
                      <tbody>
                        <template v-for="player in round.players" :key="player">
                          <tr :class="player.partnerNum == 2 ? 'teamEnd' : ''">
                            <td style="width: 20%"><span @click="player.showScorecard = !player.showScorecard">{{ player.name }}</span></td>
                            <td>{{ player.clubName }}</td>
                            <td>{{ player.hcp }}</td>
                            <td>{{ player.partnerNum == 2 ? '' : player.showTeetime != '' ? player.showTeetime : player.isStreicher ? '(' + getPlusSign(player.score) + ')' : getPlusSign(player.score) }}</td>
                            <td>{{ player.partnerNum == 2 ? '' : player.showTeetime != '' ? player.showTeetime : player.playedHoles }}</td>
                            <td>{{ player.partnerNum == 2 ? '' : player.showTeetime != '' ? player.showTeetime : player.playedHoles == 'F' ? player.isStreicher ? '(' + player.strokes + ')' : player.strokes : '-' }}</td>
                          </tr>
                          <tr v-if="player.showScorecard" class="playerScore">
                            <td colspan="6">
                              <table class="playerScore">
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th v-for="i in 18" :key="i">{{ i }}</th>
                                    <th>+/-</th>
                                    <th>Gesamt</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Par</td>
                                    <td class="small" v-for="par in player.pars" :key="par">{{ par }}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                <tr>
                                <td></td>
                                <td :class="'small ' +  getScoreClass(player.pars[index], player.scores[index])" v-for="(score, index) in player.scores" :key="index" :value="score">{{ score == 0 ? '-' : score }}</td>
                                <td class="small">{{ getPlusSign(player.score) }}</td>
                                <td>{{ player.playedHoles == 'F' ? player.strokes : '-' }}</td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                          <p class="legend">
                            <span class="square eagle"></span> - Eagle oder besser ({{ getParDiff(player.pars, player.scores, -2) }})
                            <span class="square birdie"></span> - Birdie ({{ getParDiff(player.pars, player.scores, -1) }})
                            <span class="square par"></span> - Par ({{ getParDiff(player.pars, player.scores, 0) }})
                            <span class="square bogey"></span> - Bogey ({{ getParDiff(player.pars, player.scores, 1) }})
                            <span class="square doublebogey"></span> - Double Bogey ({{ getParDiff(player.pars, player.scores, 2) }})
                            <span class="square triplebogey"></span> - Triple Bogey oder schlechter ({{ getParDiff(player.pars, player.scores, 3) }})
                          </p>
                        </td>
                        </tr>
                        </template>
                        <tr class="sum">
                          <td>Gesamt:</td>
                          <td></td>
                          <td></td>
                          <td><strong v-if="round.players.length > 0">{{ getSign(round.score) }}{{ round.score == 0 ? 'Par' : round.score }}</strong></td>
                          <td></td>
                          <td><strong v-if="round.players.length > 0 && round.players.filter(f => f.playedHoles != 'F').length == 0">{{ round.strokes }}</strong></td>
                       </tr>
                      </tbody>
                    </table>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>-->

        <ion-grid class="my-grid" v-if="scoringMode != 3 && singleScoresRounds.length > 0 && selectedViewType == 1"> <!-- Single View -->
          <ion-row class="ion-margin">
            <ion-col class="ion-no-margin ion-no-padding">
              <ion-card class="ion-no-margin ion-no-padding">
                <ion-card-content>
                  <ion-grid> 
                    <ion-row style="font-weight: bold;border-bottom: solid;">
                      <ion-col size="1">Position</ion-col>
                      <ion-col size="2">Name</ion-col>
                      <ion-col>Club</ion-col>
                      <ion-col size="1">HCPI&#8482;</ion-col>
                      <ion-col size="1">+/-</ion-col>
                      <ion-col size="1">Nach</ion-col>
                      <ion-col size="1">Gesamt</ion-col>
                    </ion-row>
                    <ion-row @click="player.showScorecard = !player.showScorecard" v-for="(player, index) in singleScoresPlayers" :key="index" :value="player" :class="index % 2 == 0 || singleScoresRounds.find(f => f.id == selectedSingleRound).playForm == 'Vierer' ? 'cursor ' : 'cursor even'" :style="player.partnerNum == 2 ? 'border-bottom: solid;' : ''">
                      <ion-col size="1">
                        {{ player.partnerNum == 2 ? '' : player.rank }}
                      </ion-col>
                      <ion-col size="2">
                        {{ player.name }}
                      </ion-col>
                      <ion-col>
                        {{ player.clubName }}
                      </ion-col>
                      <ion-col size="1">
                        {{ player.hcp }}
                      </ion-col>
                      <ion-col size="1">
                        {{ player.showTeetime != '' ? player.partnerNum == 2 ? '' : player.showTeetime : player.partnerNum == 2 ? '' : getPlusSign(player.score) }}
                      </ion-col>
                      <ion-col size="1">
                        {{ player.showTeetime != '' ? player.partnerNum == 2 ? '' : player.showTeetime : player.partnerNum == 2 ? '' : player.playedHoles }}
                      </ion-col>
                      <ion-col size="1">
                        {{ player.showTeetime != '' ? player.partnerNum == 2 ? '' : player.showTeetime : player.partnerNum == 2 ? '' : player.strokes }}
                      </ion-col>
                      <ion-row v-if="player.showScorecard">
                                <table class="playerScore">
                                  <thead>
                                    <tr>
                                      <th></th>
                                      <th v-for="i in 18" :key="i">{{ i }}</th>
                                      <th>+/-</th>
                                      <th>Gesamt</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>Par</td>
                                      <td class="small" v-for="par in player.pars" :key="par">{{ par }}</td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td :class="'small ' +  getScoreClass(player.pars[index], player.scores[index]) + ' ' + getColorClass(player.pars[index], player.scores[index])" v-for="(score, index) in player.scores" :key="index" :value="score">{{ score == 0 ? '-' : score }}</td>
                                      <td class="small">{{ getPlusSign(player.score) }}</td>
                                      <td>{{ player.playedHoles == 'F' ? player.strokes : '-' }}</td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                  </table>
                                  <p class="legend">
                                    <span class="square eagle"></span> - Eagle oder besser ({{ getParDiff(player.pars, player.scores, -2) }})
                                    <span class="square birdie"></span> - Birdie ({{ getParDiff(player.pars, player.scores, -1) }})
                                    <span class="square par"></span> - Par ({{ getParDiff(player.pars, player.scores, 0) }})
                                    <span class="square bogey"></span> - Bogey ({{ getParDiff(player.pars, player.scores, 1) }})
                                    <span class="square doublebogey"></span> - Double Bogey ({{ getParDiff(player.pars, player.scores, 2) }})
                                    <span class="square triplebogey"></span> - Triple Bogey oder schlechter ({{ getParDiff(player.pars, player.scores, 3) }})
                                  </p>
                              </ion-row>

                    </ion-row>

                  </ion-grid>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>
                <!--  <ion-row>
                    <ion-col>
                      <table class="singleRanking">
                        <thead>
                        <tr>
                          <th>Position</th>
                          <th>Name</th>
                          <th>Club</th>
                          <th>HCPI&#8482;</th>
                          <th>+/-</th>
                          <th>Nach</th>
                          <th>Gesamt</th>
                        </tr>
                        </thead>
                        <tbody>
                          <template v-for="player in singleScoresPlayers" :key="player">
                            <tr :class="player.partnerNum == 2 ? 'teamEnd' : ''">
                              <td>{{ player.partnerNum == 2 ? '' : player.rank }}</td>
                              <td><span @click="player.showScorecard = !player.showScorecard">{{ player.name }}</span></td>
                              <td>{{ player.clubName }}</td>
                              <td>{{ player.hcp }}</td>
                              <td>{{ player.showTeetime != '' ? player.partnerNum == 2 ? '' : player.showTeetime : player.partnerNum == 2 ? '' : getPlusSign(player.score) }}</td>
                              <td>{{ player.showTeetime != '' ? player.partnerNum == 2 ? '' : player.showTeetime : player.partnerNum == 2 ? '' : player.playedHoles }}</td>
                              <td>{{ player.showTeetime != '' ? player.partnerNum == 2 ? '' : player.showTeetime : player.partnerNum == 2 ? '' : player.strokes }}</td>
                            </tr>
                            <tr v-if="player.showScorecard" class="playerScore">
                              <td colspan="6">
                                <table class="playerScore">
                                  <thead>
                                    <tr>
                                      <th></th>
                                      <th v-for="i in 18" :key="i">{{ i }}</th>
                                      <th>+/-</th>
                                      <th>Gesamt</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>Par</td>
                                      <td class="small" v-for="par in player.pars" :key="par">{{ par }}</td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td :class="'small ' +  getScoreClass(player.pars[index], player.scores[index]) + ' ' + getColorClass(player.pars[index], player.scores[index])" v-for="(score, index) in player.scores" :key="index" :value="score">{{ score == 0 ? '-' : score  }}</td>
                                      <td class="small">{{ player.score == 0 ? '-' : player.score }}</td>
                                      <td>{{ player.strokes }}</td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                </table>
                                <p class="legend">
                                  <span class="square eagle"></span> - Eagle oder besser ({{ getParDiff(player.pars, player.scores, -2) }})
                                  <span class="square birdie"></span> - Birdie ({{ getParDiff(player.pars, player.scores, -1) }})
                                  <span class="square par"></span> - Par ({{ getParDiff(player.pars, player.scores, 0) }})
                                  <span class="square bogey"></span> - Bogey ({{ getParDiff(player.pars, player.scores, 1) }})
                                  <span class="square doublebogey"></span> - Double Bogey ({{ getParDiff(player.pars, player.scores, 2) }})
                                  <span class="square triplebogey"></span> - Triple Bogey oder schlechter ({{ getParDiff(player.pars, player.scores, 3) }})
                                </p>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                      </ion-col>
                  </ion-row>-->
        
                  <section>
          <ion-grid class="my-grid" v-if="scoringMode == 3 && teamScores.length > 0 && selectedViewType == 0"> <!-- Matchplay View -->
            <ion-row class="ion-justify-content-center ion-margin-start ion-margin-end ion-margin-top parent-row">
              <ion-card>
                <ion-grid :fixed="true"> 
                  <ion-row style="font-weight: normal;border-bottom: solid;border-width: 1px;">
                    <ion-col class="centercol">Team</ion-col>
                    <ion-col class="centercol">Punkte</ion-col>
                    <ion-col class="centercol">&nbsp;</ion-col>
                    <ion-col class="centercol">Punkte</ion-col>
                    <ion-col class="centercol">Team</ion-col>
                  </ion-row>
                   <ion-row style="font-weight: normal;border-bottom: solid;border-width: 1px;">
                    <ion-col :class="teamScores[0].club1Total > teamScores[0].club2Total ? 'centercol blue bold' : 'centercol'"> {{ teamScores[0].name }}</ion-col>
                    <ion-col :class="teamScores[0].club1Total > teamScores[0].club2Total ? 'centercol blue bold' : 'centercol'"> {{ teamScores[0].club1Total }}</ion-col>
                    <ion-col class="centercol">&nbsp;</ion-col>
                    <ion-col :class="teamScores[0].club1Total < teamScores[0].club2Total ? 'centercol blue bold' : 'centercol'"> {{ teamScores[0].club2Total }}</ion-col>
                    <ion-col :class="teamScores[0].club1Total < teamScores[0].club2Total ? 'centercol blue bold' : 'centercol'"> {{ teamScores[1].name }}</ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card>
            </ion-row>

            <ion-row class="ion-justify-content-center ion-margin-start ion-margin-end ion-margin-top parent-row"  v-for="round in teamScores[0].rounds" :key="round">
              <ion-card>
                <ion-grid :fixed="true"> 
                  <ion-row>
                    <div class="roundInfo">Runde {{ round.roundIndex  }} -  {{ round.playForm  }}</div>
                  </ion-row>
                  <ion-row style="font-weight: normal;border-bottom: solid;border-width: 1px;">
                    <ion-col style="text-align: right;" class="centercol">Spieler</ion-col>
                    <ion-col class="centercol">Punkte</ion-col>
                    <ion-col class="centercol">Ergebnis</ion-col>
                    <ion-col class="centercol">Punkte</ion-col>
                    <ion-col style="text-align: left;" class="centercol">Spieler</ion-col>
                  </ion-row>
                   <ion-row style="font-weight: normal;border-bottom: solid;border-width: 1px;" class="cursor" v-for="player in round.players" :key="player" @click="player.showScorecard = !player.showScorecard">
                    <ion-col style="text-align: right;" :class="player.score > player.scoreOpponent ? 'centercol blue bold' : 'centercol'"> {{ player.name }}</ion-col>
                    <ion-col :class="player.score > player.scoreOpponent ? 'centercol blue bold' : 'centercol'"> {{ player.score }}</ion-col>
                    <ion-col class="centercol">{{ player.scoreLabel }}<br v-if="player.isCompleted != ''">{{ player.isCompleted }}</ion-col>
                    <ion-col :class="player.score < player.scoreOpponent ? 'centercol blue bold' : 'centercol'"> {{ player.scoreOpponent }}</ion-col>
                    <ion-col style="text-align: left;" :class="player.score < player.scoreOpponent ? 'centercol blue bold' : 'centercol'"> {{ player.opponentName }}</ion-col>
                    <ion-row v-if="player.showScorecard">
                      <table class="playerScore matchplay">
                        <tbody>
                          <tr>
                            <td>Loch</td>
                            <td v-for="i in 18" :key="i">{{ i }}</td>
                          </tr>
                          <tr>
                            <td>{{ player.name }}</td>
                            <td v-for="label in player.scoresLabel" :key="label">{{ label != 'AS' && label != '--' ? label : '' }}</td>
                          </tr>
                          <tr class="as">
                            <td></td>
                            <td v-for="label in player.scoresLabel" :key="label">{{ label == 'AS' || label == '--' ? label : '' }}</td>
                          </tr>
                          <tr>
                            <td>{{ player.opponentName }}</td>
                            <td v-for="label in player.scoresLabelOpponent" :key="label">{{ label != 'AS' && label != '--' ? label : '' }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </ion-row>
                  </ion-row>
                </ion-grid>
              </ion-card>
            </ion-row>
          </ion-grid>
        </section>

          <!--  <ion-row>

              <ion-col>
                <table class="ranking matchplay">
                  <colgroup>
                    <col width="*">
                    <col width="100">
                    <col width="100">
                    <col width="100">
                    <col width="*">
                  </colgroup>
                  <tbody>
                    <tr>
                      <th style="text-align:right">Club</th>
                      <th>Punkte</th>
                      <th></th>
                      <th>Punkte</th>
                      <th style="text-align:left">Club</th>
                    </tr>
                    <tr>
                      <td style="text-align:right">
                      <a :href="'https://dgl-2022.golf.de/index.cfm/clubs?clubid=' + teamScores[0].clubNum" target="_blank">{{ teamScores[0].name }}</a>
                      </td>
                      <td style="text-align: center">{{ teamScores[0].club1Total }}</td>
                      <td style="text-align: center"></td>
                      <td style="text-align: center">{{ teamScores[0].club2Total }}</td>
                      <td>
                      <a :href="'https://dgl-2022.golf.de/index.cfm/clubs?clubid=' + teamScores[1].clubNum" target="_blank">{{ teamScores[1].name }}</a>
                      </td>
                    </tr>
                     <tr v-if="showBanner1"> 
                      <td colspan="6" style="text-align:center">
                        <VueScriptComponent script='<script type="text/javascript" src="https://ad1.adfarm1.adition.com/js?wp_id=4804266&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_39}"></script>' />
                      </td>
                    </tr>

                    <template v-for="round in teamScores[0].rounds" :key="round">
                      <tr>
                        <td colspan="5">
                        <div class="roundInfo"> Runde {{ round.roundIndex  }} -  {{ round.playForm  }}</div>
                        </td>
                      </tr>
                      <tr>
                        <th style="text-align:right">Spieler</th>
                        <th style="text-align: center">Punkte</th>
                        <th style="text-align: center">Ergebnis</th>
                        <th style="text-align: center">Punkte</th>
                        <th style="text-align:left">Spieler</th>
                      </tr>
                      <template v-for="player in round.players" :key="player">
                        <tr style="cursor: pointer;" @click="player.showScorecard = !player.showScorecard">
                          <td style="text-align:right">
                          <a > {{ player.name }}</a>
                          </td>
                          <td style="text-align: center;">
                          <a>{{ player.score }}</a>
                          </td>
                          <td style="text-align: center;">
                          <a>{{ player.scoreLabel }}<br v-if="player.isCompleted != ''">{{ player.isCompleted }}
                          </a>
                          </td>
                          <td style="text-align: center;">
                          <a>{{ player.scoreOpponent }}</a>
                          </td>
                          <td>
                          <a >{{ player.opponentName }}</a>
                          </td>
                        </tr>
                        <tr v-if="player.showScorecard" class="playerScore">
                          <td colspan="5">
                            <table class="playerScore matchplay">
                              <tbody>
                                <tr>
                                  <td>Loch</td>
                                  <td v-for="i in 18" :key="i">{{ i }}</td>
                                </tr>
                                <tr>
                                  <td>{{ player.name }}</td>
                                  <td v-for="label in player.scoresLabel" :key="label">{{ label != 'AS' && label != '--' ? label : '' }}</td>
                                </tr>
                                <tr class="as">
                                  <td></td>
                                  <td v-for="label in player.scoresLabel" :key="label">{{ label == 'AS' || label == '--' ? label : '' }}</td>
                                </tr>
                                <tr>
                                  <td>{{ player.opponentName }}</td>
                                  <td v-for="label in player.scoresLabelOpponent" :key="label">{{ label != 'AS' && label != '--' ? label : '' }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </template>
                      <tr>
                        <td></td>
                        <td style="text-align: center">{{ round.team1Total }}</td>
                        <td style="text-align: center"></td>
                        <td style="text-align: center">{{ round.team2Total }}</td>
                        <td></td>
                      </tr>
                      <tr v-if="round.roundIndex == 2 && showBanner2"> 
                        <td colspan="6" style="text-align:center">
                          <VueScriptComponent script='<script type="text/javascript" src="https://ad1.adfarm1.adition.com/js?wp_id=4804267&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_39}"></script>' />
                        </td>
                      </tr>
                    </template>
                </tbody>
                </table>
              </ion-col>
            </ion-row>
          </ion-card>
          </ion-grid>-->
        
        <ion-grid class="my-grid">
        <ion-row class="ion-margin parent-row">
            <div style="text-align:center;" data-header-ad-wrapper>
              <img width="1048" :src="footerImg">
            </div>
        </ion-row>
        <ion-row class="ion-margin parent-row">
            <div style="margin: 10px;color: white;"><a style="margin: 10px;color: white;" href="https://www.clubinone.de" target="_blank">Powered by CLUB IN ONE</a></div>
        </ion-row>
      </ion-grid>

      </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonGrid, IonRow, IonCol, loadingController, IonIcon, modalController, isPlatform, IonSelect, IonSelectOption, IonItem, IonCard, IonButton, IonCardHeader, IonCardContent, IonCardTitle, IonText  } from '@ionic/vue';
import { defineComponent, computed, watch, ref, onMounted } from 'vue';
import { useQuery } from '@vue/apollo-composable'
import getDGLNavigationQueryFile from '../graphql/getDGLNavigation.query.gql'
import getDGLTeamScoreQueryFile from '../graphql/getDGLTeamScore.query.gql'
import getDGLSingleScoreQueryFile from '../graphql/getDGLSingleScore.query.gql'
import { openOutline, videocamOutline, videocamOffOutline, chevronBackOutline, refreshOutline, chevronDownOutline, chevronUpOutline } from 'ionicons/icons';
import VueScriptComponent from 'vue-script-component'
import { useRoute, useRouter } from 'vue-router';
require('@/assets/images/arrow.gif')
import MarqueeText from 'vue-marquee-text-component'
import ShowLiveTableDialog from './ShowLiveTableDialog'

export default defineComponent({
  name: 'Home',
  components: {
    VueScriptComponent, IonPage, IonContent, IonGrid, IonRow, IonCol, MarqueeText, IonIcon, IonSelect, IonSelectOption, IonItem, IonCard, IonButton, IonCardHeader, IonCardContent, IonCardTitle, IonText
  },
  data() {
    return {
    }
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    let loadingSpinner = null
    let isLoaded = ref(false)
    let selectedNav = ref(undefined)
    let selectedMatchDay = ref(0)
    const selectedViewType = ref('0')
    let selectedRefresh = ref(1)
    let selectedSingleRound = ref(0)
    let showBanner1 = ref(false)
    let showBanner2 = ref(false)
    let showBanner3 = ref(false)
    let showBanner4 = ref(false)
    let showBanner5 = ref(false)
    let showDetails = ref(false)

    const { result: getDGLNavigationResult, refetch: getDGLNavigationRefetch } = useQuery(getDGLNavigationQueryFile, () => ({ league_id: parseInt(route.params.lid), parent_id: parseInt(route.params.pid) }), () => ({ fetchPolicy: 'no-cache', enabled: route.params.lid != undefined && route.params.pid != undefined } ))

    const { result: getDGLTeamScoreResult, refetch: getDGLTeamScoreRefetch, loading: getDGLTeamScoreLoading } = useQuery(getDGLTeamScoreQueryFile, () => ({ matchDayId: parseFloat(selectedMatchDay.value), viewType: selectedViewType.value }), () => ({ fetchPolicy: 'no-cache', pollInterval: selectedRefresh.value == 0 ? 0 : 60000*5,  enabled: selectedMatchDay.value != 0 && selectedViewType.value == 0 } ))

    const { result: getDGLSingleScoreResult, refetch: getDGLSingleScoreRefetch, loading: getDGLSingleScoreLoading } = useQuery(getDGLSingleScoreQueryFile, () => ({ matchDayId: parseFloat(selectedMatchDay.value), viewType: selectedViewType.value, roundId: parseInt(selectedSingleRound.value) }), () => ({ fetchPolicy: 'no-cache', pollInterval: selectedRefresh.value == 0 ? 0 : 60000*5, enabled: selectedMatchDay.value != 0 && selectedViewType.value == 1 } ))


    const refreshSelect = [{ id: 0, name: 'Nein' }, { id: 1, name: '5 Minuten', selected: true }]
    const adLinkTop = computed(() => getDGLNavigationResult.value?.getDGLNavigation.adLinkTop ?? '')
    const scoringMode = computed(() => getDGLNavigationResult.value?.getDGLNavigation.scoringMode ?? '')
    const leaguesNav = computed(() => getDGLNavigationResult.value?.getDGLNavigation.leaguesNav ?? '')
    const viewTypes = computed(() => getDGLNavigationResult.value?.getDGLNavigation.viewType ?? '')
    const matchDays = computed(() => getDGLNavigationResult.value?.getDGLNavigation.matchDays ?? '')
    const footerImg = computed(() => getDGLNavigationResult.value?.getDGLNavigation.footerImg ?? '')
    const logoImg = computed(() => getDGLNavigationResult.value?.getDGLNavigation.logoImg ?? '')
    const golfdeLink = computed(() => getDGLNavigationResult.value?.getDGLNavigation.golfdeLink ?? '')
    const teamScores = computed(() => getDGLTeamScoreResult.value?.getDGLTeamScore.teams ?? [])
    const blitzScores = computed(() => getDGLTeamScoreResult.value?.getDGLTeamScore.datablitz ?? [])
    const allFinished = computed(() => getDGLTeamScoreResult.value?.getDGLTeamScore.allFinished ?? true)
    const singleScoresPlayers = computed(() => getDGLSingleScoreResult.value?.getDGLSingleScore.players ?? [])
    const singleScoresRounds = computed(() => getDGLSingleScoreResult.value?.getDGLSingleScore.rounds ?? [])
    const tickerTeam = computed(() => getDGLTeamScoreResult.value?.getDGLTeamScore.currentTicker ?? '')
    const tickerSingle = computed(() => getDGLSingleScoreResult.value?.getDGLSingleScore.currentTicker ?? '')
    const currentTicker = computed({
        get() {
          if (tickerTeam.value && tickerTeam.value != '') return tickerTeam.value
          if (tickerSingle.value && tickerSingle.value != '') return tickerSingle.value
          return ''
        },
      })

    watch(leaguesNav, (newVal, oldVal) => {
      selectedNav.value =  newVal.find(f => f.league_id == route.params.lid)?.league_id
    })
    watch(matchDays, (newVal, oldVal) => {
      selectedMatchDay.value =  newVal.find(f => f.selected == true).id
    })
    watch(viewTypes, (newVal, oldVal) => {
      selectedViewType.value =  newVal.find(f => f.type_id == 0)?.type_id
    })
    watch(singleScoresRounds, (newVal, oldVal) => {
      if (selectedSingleRound.value == 0 || !newVal.find(f => f.id == selectedSingleRound.value))
        selectedSingleRound.value =  newVal[0].id
    })

    watch(selectedNav, (newVal, oldVal) => {
      if (oldVal != undefined && newVal != oldVal) {
        isLoaded.value = false
        router.push('/score/' + newVal + '/' + route.params.pid)
        //getDGLNavigationRefetch()
      }
    })

    watch(selectedMatchDay, (newVal, oldVal) => {
      isLoaded.value = false
    })
    watch(selectedViewType, (newVal, oldVal) => {
      isLoaded.value = false
    })
    watch(selectedSingleRound, (newVal, oldVal) => {
      isLoaded.value = false
    })
    watch(scoringMode, async (newVal, oldVal) => {
      //if (newVal == 3)
        //return
      await new Promise(resolve => setTimeout(resolve, 2000))
      showBanner1.value = true
      await new Promise(resolve => setTimeout(resolve, 1000))
      showBanner2.value = true
      await new Promise(resolve => setTimeout(resolve, 1000))
      showBanner3.value= true
      await new Promise(resolve => setTimeout(resolve, 1000))
      showBanner4.value = true
      await new Promise(resolve => setTimeout(resolve, 1000))
      showBanner5.value = true
    })


    watch([getDGLTeamScoreLoading, getDGLSingleScoreLoading], ([newLoading, newLoading1], [pervLoading, pervLoading1])=> {
      if ((!newLoading || !newLoading1) && loadingSpinner != null) {
          isLoaded.value = true
          loadingSpinner.dismiss()
          loadingSpinner = null
          return
      }
      else if (!isLoaded.value && (newLoading || newLoading1)) {
        loadingController.create().then(res => {
              loadingSpinner = res
              loadingSpinner.present().then(async () => { 
                if (!getDGLTeamScoreLoading.value && loadingSpinner) {
                  loadingSpinner.dismiss() 
                  loadingSpinner = null
                }
              })
            }).catch(err => { })
      }
    })

    return {
      adLinkTop, leaguesNav, selectedNav, viewTypes, selectedViewType, matchDays, selectedMatchDay, selectedRefresh, refreshSelect, getDGLSingleScoreRefetch, getDGLTeamScoreRefetch, teamScores, singleScoresPlayers, singleScoresRounds, currentTicker, getDGLTeamScoreLoading, getDGLSingleScoreLoading, isLoaded, selectedSingleRound, footerImg, logoImg, scoringMode, showBanner1, showBanner2, showBanner3, showBanner4, showBanner5, openOutline, isPlatform, allFinished, videocamOutline, videocamOffOutline, chevronBackOutline, refreshOutline, showDetails, chevronDownOutline, chevronUpOutline, golfdeLink, blitzScores
    }
  },
  async mounted() {
  },
  methods: {
    openInNewTab(url) {
      window.open(url, '_blank', 'noreferrer');
    },
    async openAll() {
      for (let team of this.teamScores)
        team.showDetails = !this.showDetails;
      this.showDetails = !this.showDetails
    },
    async openLiveTable() {
      const modal = await modalController
        .create({
          component: ShowLiveTableDialog,
          cssClass: this.isPlatform('mobile') ? '' : 'tournamentLists-modal',
          componentProps: {
            propsData: {
              teamScores: this.blitzScores,
              allFinished: this.allFinished,
              leagueInfo: this.matchDays.find(f => f.id == this.selectedMatchDay)?.name?.split('-')[0]?.trim()
            }
          },
        })
      return modal.present();

    },
    getParDiff(pars, scores, value) {
      let count = 0
      let parDiffCount = 0
      for (const par of pars) {
        if (value != 3 && scores[count] - par  == value)
          parDiffCount++
        if (value == 3 && scores[count] - par  >= value)
          parDiffCount++
        count++
      }
      return parDiffCount
    },
    getScoreClass(par, score) {
      if (score - par == 0)
        return 'par'
      if (score - par == -1)
        return 'birdie'
      if (score > 0 && score - par <= -2)
        return 'eagle'
      if (score - par == 1)
        return 'bogey'
      if (score - par == 2)
        return 'doublebogey'
      if (score - par > 2)
        return 'triplebogey'
    },
    getColorClass(par, score) {
      if (score - par == 0)
        return 'black'
      if (score - par == -1)
        return 'white'
      if (score > 0 && score - par <= -2)
        return 'white'
      if (score - par == 1)
        return 'black'
      if (score - par == 2)
        return 'white'
      if (score - par > 2)
        return 'white'
    },
    getSign(score) {
      return score == 0 ? '' : score > 0 ? '+' : ''
    },
    getPlusSign(score) {
      if (score == 0)
        return 'Par'
      return score > 0 ? '+' + score : score
    },
    refreshScore() {
      this.isLoaded = false
      if (this.selectedViewType == 0)
        this.getDGLTeamScoreRefetch()
      else
        this.getDGLSingleScoreRefetch()
    }
  }
});

if (document.location.search.match(/type=embed/gi)) {
  window.parent.postMessage("resize", "*");
}

</script>

<style scoped>

.gray { color: #2e2e2e;}
.blue { color: #004587;}
.bold { font-weight: bold;}

.centercol {
    text-align: center;
}

.wrapper {
    align-items: center;
    justify-items: center;
  }

.my-grid {
	background-color: #004587;
}
.parent-row {
	background-color: #004587;
    align-items: center !important;
    justify-content: center !important;
 }

 .cursor {
    cursor: pointer;
}

.even {
    background-color:  rgb(242, 242, 242);
}

.teams {
    color: #004587;
    text-decoration: none;
    outline: 0;
    font-weight: 600;
    font-size: 0.9em;
}

ion-button {
 text-transform: none;
 --border-width: 1px;
}

ion-card{
        display: flex;
        flex-direction: column;
        width: 100% !important;
        margin: 0 !important;
      }

.dgv-header-logo {
    width: 60px;
    min-width: 40px;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: 150ms;
    transition-duration: .2s;
}

.tw-h-auto {
    height: auto;
}

.cf:after {
    content: "";
    display: table;
    clear: both;
}

div.wrap,
div.error {
    padding: 7px;
}

div.wrap.noTop {
    padding-top: 0;
}

div.wrap ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

div.wrap > ul > li {
    padding-right: 4px;
    padding-bottom: 15px;
    width: 25%;
    float: left;
}

div.wrap ul li h2 {
    font: bold 1.5em/1.5 "Roboto", sans-serif;
    padding-bottom: 3px;
    border-bottom: 1px solid;
    margin: 0;
}

div.wrap > ul > li > ul {
    padding-right: 30px;
    padding-top: 7px;
}

div.wrap > ul > li > ul > li {
    padding: 10px 5px 10px 1em;
    font-size: 1.2em;
    font-weight: 600;
    cursor: pointer;
}

div.wrap > ul > li > ul > li > ul {
    padding-left: 20px;
}

div.wrap > ul > li > ul > li > ul {
    margin-top: 5px;
    padding-top: 5px;
    border-top: solid 2px;
    display: none;
}

div.wrap > ul > li > ul > li > ul > li {
    padding: 5px 0;
}

div.wrap ul a {
    text-decoration: none;
    font-weight: normal;
}

div.wrap ul a:hover {
    text-decoration: underline;
}

div.refresh {
    float: right;
    line-height: 1;
    padding-top: 7px;
}

div.refresh select {
    font-size: 12px;
    margin: 0;
    width: 100px;
}

div.refresh a {
    display: inline-block;
    cursor: pointer;
}

div.filter {
    float: left;
    max-width: 400px;
    padding-left: 7px;
}

div.filter a {
    text-decoration: none;
    background: transparent url('~@/assets/images/arrow.gif') no-repeat right center;
    padding-right: 20px;
}

div.filter select {
    display: block;
    width: 100%;
}

ion-content {
    margin: 0;
    padding: 0;
}

select {
    margin-top: 7px;
    font-size: 1.2em;
    font-weight: 600;
    height: 30px;
    max-width: 100%;
    padding: 4px 6px;
    border: 1px solid;
}

span.square {
    display: inline-block;
    width: 18px;
    height: 18px;
    border: solid 1px;
    margin-left: 20px;
    margin-bottom: -4px;
}

p.legend {
    font-size: 12px;
    text-align: right;
    padding-right: 20px;
    margin-bottom: 15px;
}

p.right {
    text-align: right;
}

img {
    max-width: 100%;
    height: auto;
}

div.controls {
    margin: 0 0 20px 0;
}

div.head {
    font-size: 19px;
    font-weight: bold;
    color: white;
    border-bottom: 1px solid;
}

div.head span {
    display: inline-block;
}

div.head span.rank {
    width: 50px;
    padding-left: 5px;
}

div.head span.score {
    width: 70px;
    float: right;
    text-align: center;
}

.ticker-text {
    height: 150%;
    font-size: 25px;
    color: red;
}

.black {
    color: black;
}

.white {
    color: white;
}

div.roundInfo {
    padding: 16px 0 5px 0;
    font-size: 18px;
}

table.ranking span,
table.singleRanking span {
    text-decoration: none;
    cursor: pointer;
}

table.ranking tr.sum > td {
    border-top: solid 2px;
}

table.ranking,
table.singleRanking {
    width: 100%;
    table-layout: auto;
    margin-bottom: 30px;
}

table.ranking tr,
table.singleRanking tr {
    height: 30px;
}

table.ranking > thead > tr > th,
table.singleRanking > thead > tr > th {
    padding: 3px 5px;
    font-weight: bold;
    font-size: 13px;
    text-align: center;
    border: solid 1px;
    border-top: none;
    border-bottom-width: 3px;
    width: 75px;
}

table.ranking > thead > tr > th:nth-child(1),
table.ranking > thead > tr > th:nth-child(2),
table.singleRanking > thead > tr > th:nth-child(2),
table.singleRanking > thead > tr > th:nth-child(3),
table.singleRanking > tbody > tr > td:nth-child(2),
table.singleRanking > tbody > tr > td:nth-child(3) {
    width: auto;
    text-align: left !important;
}

table.ranking > thead > tr > th:first-child,
table.ranking > tbody > tr > td:first-child,
table.singleRanking > thead > tr > th:first-child,
table.singleRanking > tbody > tr > td:first-child {
    border-left: none;
}

table.ranking > thead > tr > th:last-child,
table.ranking > tbody > tr > td:last-child,
table.singleRanking > thead > tr > th:last-child,
table.singleRanking > tbody > tr > td:last-child {
    border-right: none;
}

table.ranking > tbody > tr > td,
table.singleRanking > tbody > tr > td {
    font-size: 14px;
    padding: 3px 5px;
    border: solid 1px;
    text-align: center;
}

table.ranking.matchplay > tbody > tr > td {
    text-align: left;
}

table.ranking > tbody > tr > td:nth-child(1),
table.ranking > tbody > tr > td:nth-child(2) {
    text-align: left;
}

table.ranking tr.sum > td {
    border-top: solid 2px;
}

table.ranking > tbody > tr.teamEnd > td,
table.singleRanking > tbody > tr.teamEnd > td {
    border-bottom: solid 3px;
}

table.ranking tr.playerScore,
table.singleRanking tr.playerScore {
    display: table-row;
}

table.ranking tr.playerScore > td,
table.singleRanking tr.playerScore > td {
    padding: 5px 3px;
}

table.ranking a,
table.singleRanking a {
    text-decoration: none;
}

table.ranking a:hover,
table.singleRanking a:hover {
    text-decoration: underline;
}

table.ranking > tbody > tr > td {
    font-size: 14px;
    padding: 3px 5px;
    border: solid 1px;
    text-align: center;
}

table.playerScore {
    width: 100%;
}

table.playerScore thead tr th {
    font-weight: normal;
    border: solid 1px;
    border-top: none;
    border-bottom-width: 3px;
    text-align: center;
    padding: 3px 5px;
}

table.playerScore > tbody > tr > td {
    border: solid 1px;
    text-align: center;
    padding: 3px 5px;
}

table.playerScore > thead > tr > th:first-child,
table.playerScore > tbody > tr > td:first-child {
    border-left: none;
}

table.playerScore > thead > tr > th:last-child,
table.playerScore > tbody > tr > td:last-child {
    border-right: none;
}

table.playerScore td.small,
table.playerScore th.small {
    width: 32px;
}

table.playerScore tr :nth-child(2) {
    width: 40px;
}

table.playerScore tr :nth-child(21) {
    width: 58px;
}

table.playerScore tbody tr td {
}

table.playerScore tbody tr td:first-child {
    font-weight: normal !important;
    text-align: right;
}

table.playerScore tbody tr:first-child td {
    font-weight: bold;
}

table.playerScore.matchplay tr td {
    width: 50px;
}

table.playerScore.matchplay tr td:first-child {
    width: auto;
}

table.playerScore.matchplay tr.as td {
}

.eagle {
    background: #ff9050 !important;
    border-bottom: solid 1px !important;
    border-bottom-color: black !important;
    border-right: solid 1px !important;
    border-right-color: black !important;
}

.birdie {
    background: #ff7070 !important;
    border-bottom: solid 1px !important;
    border-bottom-color: black !important;
    border-right: solid 1px !important;
    border-right-color: black !important;
}

.par {
    background: white !important;
}

.bogey {
    background: lightblue !important;
    border-bottom: solid 1px !important;
    border-bottom-color: black !important;
    border-right: solid 1px !important;
    border-right-color: black !important;
}

.doublebogey {
    background: blue !important;
    border-bottom: solid 1px !important;
    border-bottom-color: black !important;
    border-right: solid 1px !important;
    border-right-color: black !important;
}

.triplebogey {
    background: darkblue !important;
    border-bottom: solid 1px !important;
    border-bottom-color: black !important;
    border-right: solid 1px !important;
    border-right-color: black !important;
}

span.square {
    display: inline-block;
    width: 18px;
    height: 18px;
    border: solid 1px;
    margin-left: 20px;
    margin-top: 2px;
}

p.legend {
    font-size: 12px;
    text-align: right;
    padding-right: 20px;
    margin-bottom: 15px;
}

p.right {
    text-align: right;
}

img {
    max-width: 100%;
    height: auto;
}
</style>